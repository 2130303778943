// PRIVATE API ROUTES

import { request, personalUrl, privateUrl, specialUrl } from "./common";

/**
 * Returns the current users document
 * Creates a new user document if userId nor email is found
 */
export async function getCurrentUser({ accessToken }) {
  const url = personalUrl(`/users/currentuser`);
  return request({ url, accessToken });
}

export async function getUsersPendingDogs({
  accessToken,
  options = { populate: "sire dam owners breeder certificates.certificate organizations.organization" },
}) {
  if (!accessToken) return;
  const url = personalUrl(`/dogs/pending`);
  return request({ url, accessToken, body: { options } });
}

export async function getUsersRegisteredDogs({
  accessToken,
  options = { populate: "sire dam owners breeder certificates.certificate organizations.organization" },
}) {
  if (!accessToken) return;
  const url = personalUrl(`/dogs/registered`);
  return request({ url, accessToken, body: { options } });
}

export async function getUsersLitters({
  accessToken,
  options = { populate: "sire dam puppies organizations.organization" },
}) {
  if (!accessToken) return;
  const url = personalUrl(`/litters/currentuser`);
  return request({ url, accessToken, body: { options } });
}

export async function getUsersPendingLitters({
  accessToken,
  options = {
    populate: "sire dam verifications organizations.organization",
  },
}) {
  if (!accessToken) return;
  const url = personalUrl(`/litters/pending/currentuser`);
  return request({ url, accessToken, body: { options } });
}

export async function getDogById(
  _id,
  { accessToken, options = { populate: "sire dam owners breeder geneticResults certificates.certificate organizations.organization" } }
) {
  const url = privateUrl(`/dogs/byId/${_id}`);
  return request({ url, accessToken, body: { options } });
}

export async function getPendingLitterById(
  _id,
  {
    accessToken,
    options = {
      populate: { path: "puppies sire dam", populate: "organizations.organization" },
    },
  }
) {
  const url = privateUrl(`/litters/pending/byId/${_id}`);
  return request({ url, accessToken, body: { options } });
}

export async function getLitterById(
  _id,
  {
    accessToken,
    options = {
      populate: { path: "puppies sire dam", populate: "organizations.organization" },
    },
  }
) {
  const url = privateUrl(`/litters/byId/${_id}`);
  return request({ url, accessToken, body: { options } });
}

export async function requestUpdateDog(_id, update, attachmentsMod, { accessToken, options }) {
  const url = privateUrl(`/dogs/byId/${_id}`);
  return request({
    url,
    method: "PATCH",
    accessToken,
    body: { update, attachmentsMod, options },
  });
}

export async function getDogsPendingDiffs(_id, {
  accessToken,
  options = { populate: "organizations.organization" },
}) {
  if (!accessToken) return;
  const url = privateUrl(`/dogs/byId/${_id}/diffs/pending`);
  return request({ url, accessToken, body: { options } });
}

export async function rejectDiff(_id, { accessToken }) {
  const url = privateUrl(`/diffs/rejectById/${_id}`);
  return request({ url, accessToken });
}


export async function updateUser(update, { accessToken }) {
  const url = privateUrl(`/users/currentuser`);
  return request({ url, method: "PATCH", accessToken, body: { update } });
}

export async function verifyUser(verifyUser, { accessToken }) {
  return updateUser({ isVerified: verifyUser }, { accessToken });
}

export async function checkIfMicrochipExists(microchip, { accessToken }) {
  const url = specialUrl(`/microchipExists/${microchip}`);
  return request({ url, method: "GET", accessToken });
}

export async function checkIfUserIsOwner(_id, { accessToken }) {
  const url = specialUrl(`/userOwnsDog/${_id}`);
  return request({ url, method: "GET", accessToken });
}

export async function createLitter(document, { accessToken }) {
  const url = privateUrl(`/litters/new`);
  return request({ url, accessToken, body: { document } });
}

export async function createPendingLitter(document, { accessToken }) {
  const url = privateUrl(`/litters/new/pending`);
  return request({ url, accessToken, body: { document } });
}

export async function createDog(document, { accessToken }) {
  const url = privateUrl(`/dogs/new/registrable`);
  return request({ url, accessToken, body: { document } });
}

// Add profile picture to an existing dog
export async function updatePrimaryImageById(_id, fileName, { accessToken }) {
  const url = privateUrl(`/dogs/updatePrimaryImage/${_id}`);
  return request({ url, method: "PATCH", accessToken, body: { fileName }  });
}

export async function addAttachment(attachment, { accessToken }) {
  const url = privateUrl(`/attachments/new`);
  return request({ url, accessToken, body: attachment });
}

export async function addTmpAttachment(attachment, { accessToken }, resize = false) {
  const url = privateUrl(`/attachments/tmp${resize ? '/resize' : ''}`);
  return request({ url, accessToken, body: attachment });
}

export async function deleteTmpAttachment(link, { accessToken }) {
  const url = privateUrl(`/attachments/tmp/deleteByUrl`);
  return request({ url, accessToken, body: { link } });
}
export async function deleteAttachment(attachment, { accessToken }) {
  // Attachment should be FormData
  const url = privateUrl(`/attachments/deleteById/${attachment.get("id")}`);
  return request({ url, accessToken, method: "DELETE" });
}

/**
 * CREATE VERIFICATION
 * @param {{accessToken: String, verification: {operation: String, parentDoc: String, parentDocType: String, user: String}}} opts
 * @param {String} verification.operation - `"verifyLitterSire" || "verifyLitterDam"`
 */
export async function createVerification(document, { accessToken }) {
  const url = privateUrl(`/verifications/new`);
  return request({ url, accessToken, body: { document } });
}

export async function getVerification(
  _id,
  { accessToken, options = { populate: "parentDoc" } }
) {
  const url = privateUrl(`/verifications/byId/${_id}`);
  return request({ url, accessToken, body: { options } });
}

export async function acceptVerification(_id, { accessToken }) {
  const url = privateUrl(`/verifications/acceptById/${_id}`);
  return request({ url, accessToken });
}

/**
 * GET ALL VERIFICATIONS OF A PARENT DOCUMENT
 * @param {String} accessToken user access token
 * @param {String} parentDoc Parent document _id
 * @param {String} parentDocType Parent document type
 */
export async function getVerifications({
  accessToken,
  parentDoc,
  parentDocType,
}) {
  const url = privateUrl(`/verifications/byParentDoc`);
  return request({
    url,
    accessToken,
    body: { query: { parentDoc, parentDocType } },
  });
}

export async function parseEmbarkPage(link, { accessToken }) {
  const body = { link };
  const url = privateUrl(`/parse/embark`);
  return request({ url, accessToken, body });
}

export async function createStatistic(data, { accessToken }) {
  const url = privateUrl(`/statistics/new`);
  return request({
    url,
    accessToken,
    body: { document: { data, type: "litterRegistration" } },
  });
}

export async function createCheckout({
  accessToken,
  parentDoc,
  parentDocType,
  organizations,
}) {
  const url = privateUrl(`/orders/new`);
  return request({
    url,
    accessToken,
    body: { document: { parentDoc, parentDocType, organizations } },
  });
}
