// React
import React, { useCallback, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import Cropper from "react-easy-crop";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { accessTokenState, loadingState, snackBarState } from "recoil/globalStates";

// MUI
import { useTheme, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import { Upload } from "@mui/icons-material";

// Font Awesome
import { faTrash } from "@fortawesome/free-solid-svg-icons";

// Local
import {
  getCroppedImg,
  iconStyle,
} from "constants/helpers";
import { addTmpAttachment, deleteTmpAttachment } from "api/privateRoutes";

export function ImageUpload({ category, recoilState }) {
  const { t } = useTranslation();
  const accessToken = useRecoilValue(accessTokenState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [formData, setFormData] = useRecoilState(recoilState);
  const images = formData.attachments.images;

  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState(null); // Store the original file name

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleImageCrop = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name); // Store the original file name
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setOpen(true);
      };
    }
  };

  const handleCropSave = async () => {
    setLoading(true);
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, fileName);
    await handleImageAdd(croppedImage);
    setOpen(false);
  };

    const handleImageAdd = async (file) => {
    setLoading(true);

    if (!file) {
      setLoading(false);
      return;
    }

    if (images[category]?.name === file.name) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: t(`File ${file.name} already added.`),
      });
      setLoading(false);
      return;
    }

    const newAttach = new FormData();
    newAttach.append("attachment", file);
    newAttach.append("type", "profileImage");
    let url = null;
    try {
      url = await addTmpAttachment(newAttach, { accessToken }, true);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: t("Failed to upload image:") + " " + error.message,
      });
      setLoading(false);
      return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        images: {
          ...prevFormData.attachments.images,
          [category]: {
            url: url,
            name: file.name,
          },
        },
      },
    }));
    setLoading(false);
  };

  const handleImageDelete = async (name) => {
    setLoading(true);
    const link = formData.attachments.images[name]?.url;

    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        images: {
          ...prevFormData.attachments.images,
          [name]: {
            url: null,
            name: null,
          },
        },
      },
    }));

    try {
      await deleteTmpAttachment(link, { accessToken });
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: t("Failed to delete image from storage:") + " " + error.message,
      });
    }

    setLoading(false);
  };

  const categoryText = category === "headImage" 
    ? t("head closeup") 
    : category === "sideImage" 
    ? t("side view") 
    : t("profile image");

  return (
    <Grid item xs={12} sm={12}>
      {images[category]?.url ? (
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <img
              src={images[category].url}
              alt={images[category].name}
              style={{ maxWidth: "100%", maxHeight: "80vh", objectFit: "contain" }}
            />
          </Grid>
          <Grid item>
            <Typography variant="body"><i>{categoryText}</i><br />{images[category].name}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => handleImageDelete(category)}>
              <FontAwesomeIcon icon={faTrash} style={iconStyle} />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Fab color="primary" size="small" component="label">
              <Upload />
              <input
                hidden
                accept="image/*"
                name={category}
                type="file"
                onChange={handleImageCrop}
              />
            </Fab>

            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
              <DialogTitle>Crop Image</DialogTitle>
              <DialogContent>
                <div style={{ position: 'relative', width: '100%', height: 300 }}>
                  <Cropper
                    image={imageSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={4 / 3}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={handleCropSave} color="primary">Save</Button>
              </DialogActions>
            </Dialog>

          </Grid>
          <Grid item>
            <Typography variant="body">Upload {categoryText}</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export function FileAttachments( {recoilState} ) {
  const { t } = useTranslation();
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [formData, setFormData] = useRecoilState(recoilState);
  const accessToken = useRecoilValue(accessTokenState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const files = formData.attachments.files || [];

  const handleFileAdd = async (e) => {
    setLoading(true);
    const { name, files: uploaded } = e.target;

    // Create objects with file metadata and content
    let newFiles = [];
    for (const newFile of uploaded) {
      // If there is already an entry in newFiles with the same name, error
      if (files.find((file) => newFile.name === file.name)) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: t(`File ${newFile.name} already added.`),
        });
        setLoading(false);
        return;
      }

      // Create FormData object for attachment file
      // and save it to blob storage
      const newAttach = new FormData();
      newAttach.append("attachment", newFile);
      let url = null;
      try {
        url = await addTmpAttachment(newAttach, { accessToken });
      } catch (error) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: t("Failed to upload file:") + " " + error.message,
        });
        setLoading(false);
        return;
      }

      newFiles.push({
        url: url,
        name: newFile.name,
        type: newFile.type,
      });
    }

    // Append newFiles to formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        files: [
          ...prevFormData.attachments.files,
          ...newFiles,
        ],
      },
    }));

    setLoading(false);
  };

  const handleFileDelete = async (index) => {
    setLoading(true);
    const link = files[index].url;
    try {
      await deleteTmpAttachment(link, { accessToken });
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: t("Failed to delete file from storage:") + " " + error.message,
      });
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        files: [
          ...prevFormData.attachments.files.slice(0, index),
          ...prevFormData.attachments.files.slice(index + 1),
        ],
      },
    }));
    setLoading(false);
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="h5">{t("Files")}</Typography>
      </Box>

      {(!files || files.length === 0) && (
        <Box mb={2}>
          <Typography variant="body">{t("No files added yet.")}</Typography>
        </Box>
      )}

      {files?.map((file, index) => (
        <TableContainer key={index}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{file.name}</TableCell>
                <TableCell>{file.type}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleFileDelete(index)}>
                    <FontAwesomeIcon icon={faDeleteLeft} style={iconStyle} />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ))}
      <Box>
        {lessThanSmall ? (
          <Fab color="primary" size="small" component="label">
            <Upload />
            <input
              hidden
              multiple
              accept=".pdf, .doc, .docx, .odt"
              name="fileAttachment"
              type="file"
              onChange={handleFileAdd}
            />
          </Fab>
        ) : (
          <Button variant="contained" component="label">
            {t("Add files")}
            <input
              hidden
              multiple
              accept=".pdf, .doc, .docx, .odt"
              name="fileAttachment"
              type="file"
              onChange={handleFileAdd}
            />
          </Button>
        )}
      </Box>
    </>
  );
}

export function LinkAttachments( {recoilState} ) {
  const { t } = useTranslation();
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [formData, setFormData] = useRecoilState(recoilState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const links = formData.attachments.links || [];
  
  const [linkText, setLinkText] = useState("");
  const { control } = useFormContext();

  const handleLinkChange = (e) => {
    setLinkText(e.target.value);
  };

  const handleLinkAdd = async () => {
    if (!linkText) {
      return;
    }

    // Check that the link is a valid URL.
    try {
      new URL(linkText);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: t(`Invalid link: ${linkText}`),
      });
      return;
    }

    const link = {
      url: linkText.trim(),
      category: "other",
      provider: "other",
    };


    if (links.find((link) => link.url === linkText)) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: t(`Link ${linkText} already added.`),
      });
      return;
    }

    // Append new link to formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        links: [
          ...prevFormData.attachments.links,
          link,
        ],
      },
    }));

    setLinkText("");
  };

  const handleLinkDelete = async (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        links: [
          ...prevFormData.attachments.links.slice(0, index),
          ...prevFormData.attachments.links.slice(index + 1),
        ],
      },
    }));
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="h5">{t("Links")}</Typography>
      </Box>
      {links.length === 0 && (
        <Box mb={2}>
          <Typography variant="body">{t("No links added yet.")}</Typography>
        </Box>
      )}
      {links?.map((link, index) => (
        <TableContainer key={index}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <IconButton onClick={() => handleLinkDelete(index)}>
                    <FontAwesomeIcon icon={faTrash} style={iconStyle} />
                  </IconButton>
                </TableCell>
                <TableCell>{link.url}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ))}
      <Grid item xs={12} sm={12} mb={2}>
        <Controller
          name="linkText"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label={t("Link (http:// or https://)")}
              value={linkText}
              onChange={handleLinkChange}
            />
          )}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleLinkAdd}
          sx={{ mt: 2 }}
        >
          {t("Save link")}
        </Button>
      </Grid>
    </>
  );
}

export function NoteAttachments( {recoilState} ) {
  const { t } = useTranslation();
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [formData, setFormData] = useRecoilState(recoilState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const notes = formData.attachments.text || [];
  const { control } = useFormContext();
  const [noteText, setNoteText] = useState("");

  const handleNoteChange = (e) => {
    setNoteText(e.target.value);
  };

  const handleNoteAdd = async () => {
    if (!noteText) {
      return;
    }

    if (notes.find((note) => note === noteText)) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: t(`Note "${noteText}" already added.`),
      });
      return;
    }

    // Append new Note to formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        text: [
          ...prevFormData.attachments.text,
          noteText,
        ],
      },
    }));

    setNoteText("");
  };

  const handleNoteDelete = async (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        text: [
          ...prevFormData.attachments.text.slice(0, index),
          ...prevFormData.attachments.text.slice(index + 1),
        ],
      },
    }));
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="h5">{t("Notes")}</Typography>
      </Box>
      {notes.length === 0 && (
        <Box mb={2}>
          <Typography variant="body">{t("No notes added yet.")}</Typography>
        </Box>
      )}
      {notes?.map((note, index) => (
        <TableContainer key={index}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <IconButton onClick={() => handleNoteDelete(index)}>
                    <FontAwesomeIcon icon={faTrash} style={iconStyle} />
                  </IconButton>
                </TableCell>
                <TableCell>{note}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ))}
      <Grid item xs={12} sm={12} mb={2}>
        <Controller
          name="noteText"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              multiline
              label={t("Note")}
              value={noteText}
              onChange={handleNoteChange}
            />
          )}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleNoteAdd}
          sx={{ mt: 2 }}
        >
          {t("Save note")}
        </Button>
      </Grid>
    </>
  );
}