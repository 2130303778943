import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

// Recoil
import { accessTokenState } from "recoil/globalStates";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  litterRegistrationState,
} from "recoil/registrationStates";
import { createAdminAllOrgDamsState, createAdminAllOrgSiresState } from "recoil/adminStates";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Divider,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";

// Local
import { createHandleInputChange } from "constants/helpers";

export default function LitterParents() {
  const { t } = useTranslation();
  const { organizationPrefix } = useParams();
  const [formData, setFormData] = useRecoilState(litterRegistrationState);
  const handleInputChange = createHandleInputChange(setFormData);

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const [open, setOpen] = useState({
    sire: false,
    dam: false,
  });

  const damsState = createAdminAllOrgDamsState(organizationPrefix);
  const siresState = createAdminAllOrgSiresState(organizationPrefix);
  const femaleDogs = useRecoilValue(damsState);
  const maleDogs = useRecoilValue(siresState);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("litterParents")}</Typography>
          </Divider>
          <Typography variant="body">
            {t("litterParentsInfoText")}
            <br />
            <br />
            {t("litterParentsInfoText2")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Divider textAlign="left" sx={{ mb: 4 }}>
            <Typography variant="h3">{t("sire")}</Typography>
          </Divider>
          <Controller
            name="sire"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, ...props } }) => (
              <Autocomplete
                options={maleDogs}
                getOptionLabel={(option) => option?.name || ""}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.name} {option.regNumber}
                  </li>
                )}
                onOpen={() => {
                  setOpen({ ...open, sire: true });
                }}
                placeholder={t("sire")}
                isOptionEqualToValue={(option, value) =>
                  option._id == value?._id
                }
                autoComplete
                noOptionsText={t("noData")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("sire")}
                    error={errors?.sire && true}
                    helperText={errors && errors?.sire?.message}
                  />
                )}
                onChange={(e, data) => {
                  onChange(data);
                  setFormData({
                    ...formData,
                    ["sire"]: data
                  });
                }}
                value={formData.sire}
                {...props}
              />
            )}
            onChange={([, data]) => data}
            defaultValue={formData.sire}
            control={control}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Divider textAlign="left" sx={{ mb: 4 }}>
            <Typography variant="h3">{t("dam")}</Typography>
          </Divider>
          <Controller
            name="dam"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, ...props } }) => (
              <Autocomplete
                options={femaleDogs}
                getOptionLabel={(option) => option?.name || ""}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.name} {option.regNumber}
                  </li>
                )}
                onOpen={() => {
                  setOpen({ ...open, dam: true });
                }}
                placeholder={t("dam")}
                isOptionEqualToValue={(option, value) =>
                  option._id == value?._id
                }
                autoComplete
                noOptionsText={t("noData")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("dam")}
                    error={errors?.dam && true}
                    helperText={errors && errors?.dam?.message}
                  />
                )}
                onChange={(e, data) => {
                  onChange(data);
                  setFormData({
                    ...formData,
                    ["dam"]: data
                  });
                }}
                value={formData.dam}
                {...props}
              />
            )}
            onChange={([, data]) => data}
            defaultValue={formData.dam}
            control={control}
          />
        </Grid>
      </Grid>
    </>
  );
}
