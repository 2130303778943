import React from "react";
import { Button, Stack } from "@mui/material";

export default function AcceptButton({
  action="accept",
  formType,
  display,
  handleAccept,
}) {

  if (!display) return;

  let buttonText = action === "accept" ? "Accept" : "Reject";
  if (formType === "diff") {
    buttonText = buttonText + " Update";
  } else {
    buttonText = buttonText + " Registration";
  }

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      justifyContent="flex-end"
      alignItems="center"
      sx={{ mb: 2 }}
    >
        <Button variant="contained" onClick={handleAccept}>
          {buttonText}
        </Button>
    </Stack>
  );
}
