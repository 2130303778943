import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Controller } from "react-hook-form";
import Cropper from 'react-easy-crop';

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import {
  accessTokenState,
  loadingState,
  snackBarState,
} from "recoil/globalStates";
import { allResultProvidersState } from "recoil/publicStates";
import { attachmentsModState } from "recoil/adminStates";

// MUI
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  useMediaQuery,
  useTheme,
  Icon,
} from "@mui/material";
import { Upload } from "@mui/icons-material";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashRestore, faPlusSquare } from "@fortawesome/free-solid-svg-icons";

// Routes
// import { getAttachments } from "api/adminRoutes";
import { getAttachments } from "api/publicRoutes";
import { addTmpAttachment, deleteTmpAttachment } from "api/privateRoutes";

// Local
import { adminUrl, request } from "api/common";
import { imageServer } from "constants/values";
import { getCroppedImg, iconStyle } from "constants/helpers";

// Sort the list by resultProvider.name and put "other" last
const sortResultProviders = (resultProvidersList) => {
  const resultProviders = [...resultProvidersList].sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  const otherProviderIndex = resultProviders.findIndex(provider => provider._id === "other");
  const otherProvider = resultProviders.splice(otherProviderIndex, 1)[0];
  if (otherProvider) {
    resultProviders.push(otherProvider);
  }
  return resultProviders;
};

export default function EditDogAttachments ({ dog, diff, formMethods  }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useRecoilState(loadingState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [attachmentsMod, setAttachmentsMod] = useRecoilState(attachmentsModState);
  const files = attachmentsMod.files || [];
  const images = attachmentsMod.images || [];
  const links = attachmentsMod.links || [];
  const text = attachmentsMod.text || [];

  useEffect(() => {
    getAttachments({ query: { parentDoc: dog._id } }).then(
      (data) => {
        setLoading(true);
        const startAttachments = {
          images: [],
          files: [],
          links: [],
          text: [],
        };
        data.sort((a, b) => {
          if (a.dateAdded < b.dateAdded) return -1;
          if (a.dateAdded > b.dateAdded) return 1;
          return 0;
        });
        data.forEach((attachment) => {
          if (attachment.__t === "ImageAttachment") {
            startAttachments.images.push(attachment);
          } else if (attachment.__t === "FileAttachment") {
            startAttachments.files.push(attachment);
          } else if (attachment.__t === "LinkAttachment") {
            startAttachments.links.push(attachment);
          } else if (attachment.__t === "TextAttachment") {
            startAttachments.text.push(attachment);
          }
        });

        // Primary image
        const primaryImage = dog.primaryImage;
        // Find the attachment in attachmentsMod.images.current that matches originalName
        let primaryIndex = startAttachments.images.findIndex((image) => image.originalName === primaryImage);
        if (primaryIndex === -1) {
          primaryIndex = 0;
        }
        setAttachmentsMod( {
          primaryIndex: primaryIndex,
          primaryCategory: "current",
          images: {
            current: startAttachments.images,
            add: [],
            delete: [],
          },
          files: {
            current: startAttachments.files,
            add: [],
            delete: [],
          },
          links: {
            current: startAttachments.links,
            add: [],
            delete: [],
          },
          text: {
            current: startAttachments.text,
            add: [],
            delete: [],
          },
        });
        setLoading(false);
      }
    );
  }, []);

  const DogAttachmentsFilesList = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const accessToken = useRecoilValue(accessTokenState);
    const resultProviders = sortResultProviders(useRecoilValue(allResultProvidersState));
    const { control } = useFormContext();

    // { value: 'health', label: 'Health test result' },
    const categoryOptions = [
      { value: 'genetic', label: 'Genetic test result' },
      { value: 'other', label: 'Other' },
    ];

    const handleFileAdd = async (e) => {
      setLoading(true);
      const { name, files: uploaded } = e.target;

      // Create objects with file metadata and content
      let newFiles = [];
      for (const newFile of uploaded) {
        // If there is already an entry with the same name, error
        if (files.current.find((file) => newFile.name === file.originalName) ||
            files.add.find((file) => newFile.name === file.name)) {
          setSnackbarState({
            open: true,
            severity: "error",
            message: t(`File ${newFile.name} already added.`),
          });
          setLoading(false);
          return;
        }

        // Create FormData object for attachment file
        // and save it to blob storage
        const newAttach = new FormData();
        newAttach.append("attachment", newFile);
        let url = null;
        try {
          url = await addTmpAttachment(newAttach, {accessToken});
        } catch (error) {
          setSnackbarState({
            open: true,
            severity: "error",
            message: t("Failed to upload file:") + " " + error.message,
          });
          setLoading(false);
          return;
        }

        newFiles.push({
          url: url,
          name: newFile.name,
          type: newFile.type,
        });
      }

      // Append newFiles to AttachmentsMod
      setAttachmentsMod((prevAttachmentsMod) => ({
        ...prevAttachmentsMod,
        files: {
          ...prevAttachmentsMod.files,
          add: [ 
            ...prevAttachmentsMod.files.add,
            ...newFiles ],
        },
      }));
      setLoading(false);
    };

    const handleFileDelete = async (index, status) => {
      switch (status) {
        case "current":
          handleCurrentFileDelete(index);
          break;
        case "add":
          handleNewFileDelete(index);
          break;
        case "delete":
          handleFileRestore(index);
          break;
        default:
          break;
      }
    };

    const handleCurrentFileDelete = (index) => {
      setLoading(true);

      // Move from "current" list to "delete" list
      setAttachmentsMod((prevAttachmentsMod) => ({
        ...prevAttachmentsMod,
        files: {
          ...prevAttachmentsMod.files,
          delete: [
            ...prevAttachmentsMod.files.delete,
            prevAttachmentsMod.files.current[index],
          ],
          current: [
            ...prevAttachmentsMod.files.current.slice(0, index),
            ...prevAttachmentsMod.files.current.slice(index + 1),
          ],
        }}));
      setLoading(false);
    };

    const handleNewFileDelete = async (index) => {
      setLoading(true);
      const link = files.add[index].url;
      try {
        await deleteTmpAttachment(link, {accessToken});
      } catch (error) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: t("Failed to delete file from storage:") + " " + error.message,
        });
      }

      setAttachmentsMod((prevAttachmentsMod) => ({
        ...prevAttachmentsMod,
        files: {
          ...prevAttachmentsMod.files,
          add: [
            ...prevAttachmentsMod.files.add.slice(0, index),
            ...prevAttachmentsMod.files.add.slice(index + 1),
          ],
      }}));
      setLoading(false);
    };

    const handleFileRestore = (index) => {
      // Move from "delete" list to "current" list
      setAttachmentsMod((prevAttachmentsMod) => ({
        ...prevAttachmentsMod,
        files: {
          ...prevAttachmentsMod.files,
          current: [
            ...prevAttachmentsMod.files.current,
            prevAttachmentsMod.files.delete[index],
          ],
          delete: [
            ...prevAttachmentsMod.files.delete.slice(0, index),
            ...prevAttachmentsMod.files.delete.slice(index + 1),
          ],
        }}));
    };

    const handleFileView = (attachment) => {
      const url = adminUrl([`/attachments/byId/${attachment._id}/file`]);
      request({ url, method: "GET", accessToken, raw: true })
        .then((response) => response.blob())
        .then((blob) => {
          const file = window.URL.createObjectURL(blob);
          const fakeLink = document.createElement("a");
          fakeLink.href = file;
          fakeLink.target = "_blank";
          document.body.appendChild(fakeLink);
          fakeLink.click();
          window.URL.revokeObjectURL(file);
          document.body.removeChild(fakeLink);
        })
        .catch((error) => {          
          setSnackbarState({
            open: true,
            severity: "error",
            message: t("Error fetching or processing attachment:") + " " + error.message,
          });
        });
    };

    const handleFileUpdate = (index, status, field, value) => {
      setAttachmentsMod((prevAttachmentsMod) => {
        const files = { ...prevAttachmentsMod.files };
        const fileArray = files[status] || [];
    
        return {
          ...prevAttachmentsMod,
          files: {
            ...files,
            [status]: [
              ...fileArray.slice(0, index),
              {
                ...fileArray[index],
                [field]: value,
              },
              ...fileArray.slice(index + 1),
            ],
          },
        };
      });
    };

    const FileRow = ({ file, index, status }) => {
      return (
        <TableRow key={index}>
        <TableCell>
          <Link to="#" onClick={() => handleFileView(file)}>
            <span style={status === 'delete' ? { textDecoration: 'line-through', color: 'red' } : {}}>
              {status === "add" ? file.name : file.originalName}
            </span>
            </Link>
        </TableCell>
        <TableCell>
          {status === "add" ? "-" : file.mime}
        </TableCell>
        <TableCell>
          <Controller
            name={`files[${status}][${index}].category`}
            control={control}
            defaultValue={file.category}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  {...field}
                  label="Category"
                  onChange={(event) => {
                    const value = event.target.value;
                    handleFileUpdate(index, status, 'category', value);
                    field.onChange(value);
                  }}
                >
                  {categoryOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </TableCell>
        <TableCell>
        <Controller
            name={`files[${status}][${index}].provider`}
            control={control}
            defaultValue={file.provider || null}
            disabled={!file.category || file.category.value === "other"}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel>Provider</InputLabel>
                <Select
                  {...field}
                  label="Provider"
                  onChange={(event) => {
                    const value = event.target.value;
                    handleFileUpdate(index, status, 'provider', value);
                    field.onChange(value);
                  }}
                  >
                  {resultProviders.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </TableCell>
        <TableCell>
          <IconButton onClick={() => handleFileDelete(index, status)}>
            <FontAwesomeIcon icon={faTrash} style={iconStyle} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

    return (
      <>
        <Box mb={2}>
          <Typography variant="h5">{t("Files")}</Typography>
        </Box>

        {(files.add.length + files.current.length + files.delete + length == 0) && (
          <Box mb={2}>
            <Typography variant="body">{t("No files added yet.")}</Typography>
          </Box>
        )}

          <TableContainer>
            <Table>
              <TableBody>
                {files.current?.map((file, index) => (
                  <FileRow key={index} file={file} index={index} status="current" />
                ))}
                {files.add?.map((file, index) => (
                  <FileRow key={index} file={file} index={index} status="add" />
                ))}
                {files.delete?.map((file, index) => (
                  <FileRow key={index} file={file} index={index} status="delete" />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
    <Box>
      {lessThanSmall ? (
        <Fab color="primary" size="small" component="label">
          <Upload />
          <input
            hidden
            multiple
            accept=".pdf, .doc, .docx, .odt"
            name="fileAttachment"
            type="file"
            onChange={handleFileAdd}
          />
        </Fab>
      ) : (
        <Button variant="contained" component="label">
          {t("Add files")}
          <input
            hidden
            multiple
            accept=".pdf, .doc, .docx, .odt"
            name="fileAttachment"
            type="file"
            onChange={handleFileAdd}
          />
        </Button>
      )}
    </Box>
    </>
  );
};

const DogAttachmentsImagesList = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState(null);
  
  const accessToken = useRecoilValue(accessTokenState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [attachmentsMod, setAttachmentsMod] = useRecoilState(attachmentsModState);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  
  const handleImageCrop = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name); // Store the original file name
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setOpen(true);
      };
    }
  };
  
  const handleCropSave = async () => {
    setLoading(true);
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, fileName);
    await handleImageAdd(croppedImage);
    setOpen(false);
  };
  
  const handleImageAdd = async (file) => {
    setLoading(true);
  
    if (!file) {
      setLoading(false);
      return;
    }
  
    if (images.current.find((image) => image.originalName === file.name) ||
      images.add.find((image) => image.name === file.name)) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: t(`File ${file.name} already added.`),
      });
      setLoading(false);
      return;
    }
  
    const newAttach = new FormData();
    newAttach.append("attachment", file);
    let url = null;
    try {
      url = await addTmpAttachment(newAttach, { accessToken }, true);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: t("Failed to upload image:") + " " + error.message,
      });
      setLoading(false);
      return;
    }
  
    const newImages = [{
      url: url,
      name: file.name,
      type: file.type,
    }];
  
    setAttachmentsMod((prevAttachmentsMod) => ({
      ...prevAttachmentsMod,
      images: {
        ...prevAttachmentsMod.images,
        add: [
          ...prevAttachmentsMod.images.add,
          ...newImages
        ],
      },
    }));
    setLoading(false);
  };

  const handleCurrentImageDelete = async (index) => {
    if (attachmentsMod.primaryCategory === "current" &&
        attachmentsMod.primaryIndex === index) {
      setAttachmentsMod((prevAttachmentsMod) => ({
        ...prevAttachmentsMod,
        primaryIndex: 0,
        primaryCategory: "delete",
      }));
    }

    setAttachmentsMod((prevAttachmentsMod) => ({
      ...prevAttachmentsMod,
      images: {
        ...prevAttachmentsMod.images,
        delete: [
          prevAttachmentsMod.images.current[index],
          ...prevAttachmentsMod.images.delete,
        ],
        current: [
          ...prevAttachmentsMod.images.current.slice(0, index),
          ...prevAttachmentsMod.images.current.slice(index + 1),
        ],
      },
    }));
  };

  // Remove from "new" list in attachmentsmod and delete tmp attachment.
  const handleNewImageDelete = async (index) => {
    setLoading(true);

    if (attachmentsMod.primaryCategory === "add" &&
      attachmentsMod.primaryIndex === index) {
        setAttachmentsMod((prevAttachmentsMod) => ({
          ...prevAttachmentsMod,
          primaryIndex: null,
          primaryCategory: null,
        }));
      }

    const link = images.add[index].url;
    try {
      await deleteTmpAttachment(link, {accessToken});
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: t("Failed to delete image from storage:") + " " + error.message,
      });
    }

    setAttachmentsMod((prevAttachmentsMod) => ({
      ...prevAttachmentsMod,
      images: {
        ...prevAttachmentsMod.images,
        add: [
          ...prevAttachmentsMod.images.add.slice(0, index),
          ...prevAttachmentsMod.images.add.slice(index + 1),
        ],
      },
    }));

    setLoading(false);
  };

  const handleImageRestore = async (index) => {

    if (attachmentsMod.primaryCategory === "delete" &&
        attachmentsMod.primaryIndex === index) {
      setAttachmentsMod((prevAttachmentsMod) => ({
        ...prevAttachmentsMod,
        primaryIndex: 0,
        primaryCategory: "current",
      }));
    }

    setAttachmentsMod((prevAttachmentsMod) => ({
      ...prevAttachmentsMod,
      images: {
        ...prevAttachmentsMod.images,
        current: [
          prevAttachmentsMod.images.delete[index],
          ...prevAttachmentsMod.images.current,
        ],
        delete: [
          ...prevAttachmentsMod.images.delete.slice(0, index),
          ...prevAttachmentsMod.images.delete.slice(index + 1),
        ],
      },
    }));
  };

  const handlePrimaryChange = (index, category) => {
    setAttachmentsMod((prevAttachmentsMod) => ({
      ...prevAttachmentsMod,
      primaryIndex: index,
      primaryCategory: category,
    }));
  };

  /* Main */

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Box mb={2}>
          <Typography variant="h5">{t("Images")}</Typography>
          {images.current.length + images.add.length + images.delete.length == 0 && (
            <Typography variant="body">{t("No images added yet.")}</Typography>
          )}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>{t("File")}</TableCell>
                  <TableCell>{t("Primary?")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {images.current?.map((image, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Link to={`${imageServer}/${image.path}/${image.fileName}`} target="_blank">
                        <img
                          src={`${imageServer}/${image.alternates.thumbnail.path}/${image.fileName}`}
                          alt={image.originalName}
                          style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                        />
                      </Link>
                    </TableCell>
                    <TableCell>{image.originalName}</TableCell>
                    <TableCell>
                      <Radio
                        checked={attachmentsMod.primaryIndex === index && attachmentsMod.primaryCategory === 'current'}
                        onChange={() => handlePrimaryChange(index, 'current')}
                        value={index}
                        name="primaryImage"
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleCurrentImageDelete(index)}>
                        <FontAwesomeIcon icon={faTrash} style={iconStyle} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
  
                {images.add.map((image, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Link to={image.url} target="_blank">
                        <img
                          src={image.url}
                          alt={image.name}
                          style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                        />
                      </Link>
                    </TableCell>
                    <TableCell>{image.name}</TableCell>
                    <TableCell>
                      <Radio
                        checked={attachmentsMod.primaryIndex === index && attachmentsMod.primaryCategory === 'add'}
                        onChange={() => handlePrimaryChange(index, 'add')}
                        value={index}
                        name="primaryImage"
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleNewImageDelete(index)}>
                        <FontAwesomeIcon icon={faTrash} style={iconStyle} />
                      </IconButton>
                    </TableCell>
                    </TableRow>
                  ))}
  
                {images.delete?.map((image, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Link to={`${imageServer}/${image.path}/${image.fileName}`} target="_blank">
                        <img
                          src={`${imageServer}/${image.path}/${image.fileName}`}
                          alt={image.originalName}
                          style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                        />
                      </Link>
                    </TableCell>
                    <TableCell>
                      <span style={{ textDecoration: 'line-through', color: 'red' }}>
                        {image.originalName}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Radio
                        checked={attachmentsMod.primaryIndex === index && attachmentsMod.primaryCategory === 'delete'}
                        onChange={() => handlePrimaryChange(index, 'delete')}
                        value={index}
                        name="primaryImage"
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleImageRestore(index)}>
                        <FontAwesomeIcon icon={faTrashRestore} style={iconStyle} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box>
          {lessThanSmall ? (
            <Fab color="primary" size="small" component="label">
              <Upload />
              <input
                hidden
                multiple
                accept="image/*"
                name="profileImage"
                type="file"
                onChange={handleImageCrop}
              />
            </Fab>
          ) : (
            <Button variant="contained" component="label">
              {t("Add images")}
              <input
                hidden
                multiple
                accept="image/*"
                name="profileImage"
                type="file"
                onChange={handleImageCrop}
              />
            </Button>
          )}
        </Box>
  
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
          <DialogTitle>Crop Image</DialogTitle>
          <DialogContent>
            <div style={{ position: 'relative', width: '100%', height: 300 }}>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={4 / 3}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={handleCropSave} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};


const LinkRow = ({ link, index, status, handleLinkUpdate, handleLinkDelete, handleLinkRestore }) => {
  const { control } = useFormContext();
  const resultProviders = sortResultProviders(useRecoilValue(allResultProvidersState));

  const categoryOptions = [
    { value: 'genetic', label: 'Genetic test result' },
    // { value: 'health', label: 'Health test result' },
    { value: 'other', label: 'Other' },
  ];

  return (
    <TableRow key={index}>
      <TableCell>
        <Link to={link.url} target="_blank">
          <span style={status === 'delete' ? { textDecoration: 'line-through', color: 'red' } : {}}>
            {link.url}
          </span>
        </Link>
      </TableCell>
      <TableCell>
        <Controller
          name={`links[${status}][${index}].category`}
          control={control}
          defaultValue={link.category || 'other'}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                {...field}
                label="Category"
                onChange={(event) => {
                  const value = event.target.value;
                  handleLinkUpdate(index, status, 'category', value);
                  field.onChange(value);
                }}
              >
                {categoryOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          name={`links[${status}][${index}].provider`}
          control={control}
          defaultValue={link.provider || null}
          disabled={link.category === "other"}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel>Provider</InputLabel>
              <Select
                {...field}
                label="Provider"
                onChange={(event) => {
                  const value = event.target.value;
                  handleLinkUpdate(index, status, 'provider', value);
                  field.onChange(value);
                }}
                >
                {resultProviders.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </TableCell>
      <TableCell>
        <IconButton onClick={() => handleLinkDelete(index, status)}>
          <FontAwesomeIcon icon={status === 'delete' ? faTrashRestore : faTrash} style={iconStyle} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const DogAttachmentsLinksList = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [linkText, setLinkText] = useState("");
  const { control } = useFormContext();

  const handleLinkChange = (e) => {
    setLinkText(e.target.value);
  };

  const handleLinkAdd = async () => {
    if (!linkText) {
      return;
    }

    const link = {
      url: linkText.trim(),
      category: "other",
      provider: "other",
    };

    if (links.current.find((link) => link.url === linkText) ||
        links.add.find((link) => link.url === linkText)
      ) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: t(`Link "${linkText}" already added.`),
      });
      return;
    }

    // Append new Link to AttachmentsMod
    setAttachmentsMod((prevAttachmentsMod) => ({
      ...prevAttachmentsMod,
      links: {
        ...prevAttachmentsMod.links,
        add: [
          ...prevAttachmentsMod.links.add,
          link,
        ],
      }}));
    setLinkText("");
  };

  const handleLinkUpdate = (index, status, field, value) => {
    setAttachmentsMod((prevAttachmentsMod) => {
      const links = { ...prevAttachmentsMod.links };
      const linkArray = links[status] || [];
  
      return {
        ...prevAttachmentsMod,
        links: {
          ...links,
          [status]: [
            ...linkArray.slice(0, index),
            {
              ...linkArray[index],
              [field]: value,
            },
            ...linkArray.slice(index + 1),
          ],
        },
      };
    });
  };

  const handleLinkDelete = (index, status) => {
    switch (status) {
      case "current":
        handleCurrentLinkDelete(index);
        break;
      case "add":
        handleNewLinkDelete(index);
        break;
      case "delete":
        handleLinkRestore(index);
        break;
      default:
        break;
    }
  };

  // Remove from "add" list
  const handleNewLinkDelete = async (index) => {
    setAttachmentsMod((prevAttachmentsMod) => ({
      ...prevAttachmentsMod,
      links: {
        ...prevAttachmentsMod.links,
        add: [
          ...prevAttachmentsMod.links.add.slice(0, index),
          ...prevAttachmentsMod.links.add.slice(index + 1),
        ],
      },
    }));
  };

  // Move from "current" list to "delete" list
  const handleCurrentLinkDelete = async (index) => {
    setAttachmentsMod((prevAttachmentsMod) => ({
      ...prevAttachmentsMod,
      links: {
        ...prevAttachmentsMod.links,
        delete: [
          ...prevAttachmentsMod.links.delete,
          prevAttachmentsMod.links.current[index],
        ],
        current: [
          ...prevAttachmentsMod.links.current.slice(0, index),
          ...prevAttachmentsMod.links.current.slice(index + 1),
        ],
      },
    }));
  };

  // Move from "delete" list to "current" list
  const handleLinkRestore = async (index) => {
    setAttachmentsMod((prevAttachmentsMod) => ({
      ...prevAttachmentsMod,
      links: {
        ...prevAttachmentsMod.links,
        current: [
          ...prevAttachmentsMod.links.current,
          prevAttachmentsMod.links.delete[index],
        ],
        delete: [
          ...prevAttachmentsMod.links.delete.slice(0, index),
          ...prevAttachmentsMod.links.delete.slice(index + 1),
        ],
      },
    }));
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="h5">{t("Links")}</Typography>
      </Box>
      {links.add.length + links.current.length + links.delete.length == 0 && (
        <Box mb={2}>
          <Typography variant="body">{t("No links added yet.")}</Typography>
        </Box>
      )}

      <TableContainer>
        <Table>
          <TableBody>
            {links.current?.map((link, index) => (
              <LinkRow
                key={index}
                link={link}
                index={index}
                status="current"
                handleLinkUpdate={handleLinkUpdate}
                handleLinkDelete={handleLinkDelete}
                handleLinkRestore={handleLinkRestore}
              />
            ))}
            {links.add?.map((link, index) => (
              <LinkRow
                key={index}
                link={link}
                index={index}
                status="add"
                handleLinkUpdate={handleLinkUpdate}
                handleLinkDelete={handleLinkDelete}
                handleLinkRestore={handleLinkRestore}
              />
            ))}
            {links.delete?.map((link, index) => (
              <LinkRow
                key={index}
                link={link}
                index={index}
                status="delete"
                handleLinkUpdate={handleLinkUpdate}
                handleLinkDelete={handleLinkDelete}
                handleLinkRestore={handleLinkRestore}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12} sm={12} mb={2}>
        <Controller
          name="linkText"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label={t("Link (http:// or https://)")}
              value={linkText}
              onChange={handleLinkChange}
            />
          )}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleLinkAdd}
          sx={{ mt: 2 }}
        >
          {t("Add link")}
        </Button>
      </Grid>
    </>
  );
};

const DogAttachmentsNotesList = () => {
  const { t, i18n } = useTranslation();
  const { control } = useFormContext();
  const [noteText, setNoteText] = useState("");

  const handleNoteChange = (e) => {
    setNoteText(e.target.value);
  };

  const handleNoteAdd = async () => {
    if (!noteText) {
      return;
    }

    if (text.current.find((note) => note === noteText) ||
        text.add.find((note) => note === noteText)
      ) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: t(`Note "${noteText}" already added.`),
      });
      return;
    }

    // Append new Note to AttachmentsMod
    setAttachmentsMod((prevAttachmentsMod) => ({
      ...prevAttachmentsMod,
      text: {
        ...prevAttachmentsMod.text,
        add: [
          ...prevAttachmentsMod.text.add,
          noteText,
        ],
      }}));
    setNoteText("");
  };

  // Remove from "add" list
  const handleNewNoteDelete = async (index) => {
    setAttachmentsMod((prevAttachmentsMod) => ({
      ...prevAttachmentsMod,
      text: {
        ...prevAttachmentsMod.text,
        add: [
          ...prevAttachmentsMod.text.add.slice(0, index),
          ...prevAttachmentsMod.text.add.slice(index + 1),
        ],
      },
    }));
  };

  // Move from "current" list to "delete" list
  const handleCurrentNoteDelete = async (index) => {
    setAttachmentsMod((prevAttachmentsMod) => ({
      ...prevAttachmentsMod,
      text: {
        ...prevAttachmentsMod.text,
        delete: [
          ...prevAttachmentsMod.text.delete,
          prevAttachmentsMod.text.current[index],
        ],
        current: [
          ...prevAttachmentsMod.text.current.slice(0, index),
          ...prevAttachmentsMod.text.current.slice(index + 1),
        ],
      },
    }));
  };

  // Move from "delete" list to "current" list
  const handleNoteRestore = async (index) => {
    setAttachmentsMod((prevAttachmentsMod) => ({
      ...prevAttachmentsMod,
      text: {
        ...prevAttachmentsMod.text,
        current: [
          ...prevAttachmentsMod.text.current,
          prevAttachmentsMod.text.delete[index],
        ],
        delete: [
          ...prevAttachmentsMod.text.delete.slice(0, index),
          ...prevAttachmentsMod.text.delete.slice(index + 1),
        ],
      },
    }));
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="h5">{t("Notes")}</Typography>
      </Box>
      {text.add.length + text.current.length + text.delete.length == 0 && (
        <Box mb={2}>
          <Typography variant="body">{t("No notes added yet.")}</Typography>
        </Box>
      )}
        <TableContainer>
          <Table>
            <TableBody>

            {text.current?.map((note, index) => (
              <TableRow key={index}>
              <TableCell>
                  <IconButton onClick={() => handleCurrentNoteDelete(index)}>
                    <FontAwesomeIcon icon={faTrash} style={iconStyle} />
                  </IconButton>
                </TableCell>
                <TableCell>
                  {note.text}
                </TableCell>
              </TableRow>
            ))}

            {text.add?.map((note, index) => (
              <TableRow key={index}>
              <TableCell>
                  <IconButton onClick={() => handleNewNoteDelete(index)}>
                    <FontAwesomeIcon icon={faTrash} style={iconStyle} />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <FontAwesomeIcon icon={faPlusSquare} style={iconStyle} /> {note}
                </TableCell>
              </TableRow>
            ))}

            {text.delete?.map((note, index) => (
              <TableRow key={index}>
              <TableCell>
                  <IconButton onClick={() => handleNoteRestore(index)}>
                    <FontAwesomeIcon icon={faTrashRestore} style={iconStyle} />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <span style={{ textDecoration: 'line-through', color: 'red' }}>
                    {note.text}
                  </span>
                </TableCell>
              </TableRow>
            ))}

            </TableBody>
          </Table>
        </TableContainer>
      <Grid item xs={12} sm={12} mb={2}>
        <Controller
          name="noteText"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              multiline
              label={t("Note")}
              value={noteText}
              onChange={handleNoteChange}
            />
          )}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleNoteAdd}
          sx={{ mt: 2 }}
        >
          {t("Add note")}
        </Button>
      </Grid>
    </>
  );
};

/* Main */

return (
<Grid container>
  <Grid item xs={12} sm={12}>
    </Grid>

    <Grid item xs={12} sm={12} mb={2}>
      <DogAttachmentsImagesList />
    </Grid>

    <Grid item xs={12} sm={12} mb={2}>
      <DogAttachmentsFilesList />
    </Grid>

    <Grid item xs={12} sm={12}>
      <DogAttachmentsLinksList />
    </Grid>

    <Grid item xs={12} sm={12}>
      <DogAttachmentsNotesList />
    </Grid>
  </Grid>
);
}
