import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useForm, FormProvider,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

// Recoil
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import {
  accessTokenState,
  loadingState,
  snackBarState,
  userState,
} from "recoil/globalStates";
import {
  adminDiffByParentIdState,
  attachmentsModState,
} from "recoil/adminStates";

// MUI
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { ExpandMore, Launch } from "@mui/icons-material";

// Local
import { getDogType, regOtherToAncestry, compareRole } from "constants/helpers";
import { getDogById, getUserById } from "api/adminRoutes";

// Routes
import {
  acceptDiff,
  deleteDog,
  registerDog,
} from "api/adminRoutes";
import { rejectDiff } from "api/privateRoutes";

// Components
import EditDogInformation from "components/formComponents/editDog/editDogInformation";
import EditDogRegistrationInformation from "components/formComponents/editDog/editDogRegistrationInformation";
import EditDogTesting from "components/formComponents/editDog/editDogTesting";
import EditDogBreed from "components/formComponents/editDog/editDogBreed";
import EditDogAppearance from "components/formComponents/editDog/editDogAppearance";
import EditDogAttachments from "components/formComponents/editDog/editDogAttachments";
import AcceptButton from "components/adminDashboardComponents/formRegistrationAcceptButton";
import FormHeader from "components/adminDashboardComponents/formHeader";
import FormButtons from "components/adminDashboardComponents/formButtons";

// This form is also used for pending dogs and tree dogs
export default function DogEditForm({
  dog,
  emitHandleSubmit,
}) {
  const { organizationPrefix } = useParams();
  const { t, i18n } = useTranslation();

  const accessToken = useRecoilValue(accessTokenState);
  const currentUser = useRecoilValue(userState);  
  const setLoading = useSetRecoilState(loadingState);

  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [loadingOverlayState, setLoadingOverlayState] = useRecoilState(loadingState);
  const resetFormData = useResetRecoilState(attachmentsModState);
  const [attachmentsMod, setAttachmentsMod] = useRecoilState(attachmentsModState);

  const theme = useTheme();
  const [initialDog, setInitialDog] = useState(dog);
  const [populatedDiff, setPopulatedDiff] = useState(null);
  let diff = null;
  if (compareRole(currentUser.role, "manager"))
    diff = useRecoilValue(adminDiffByParentIdState("dog"));

  const redirect = `/${organizationPrefix}/admin/dog/${initialDog._id}`;

  useEffect(() => {
    const fetchDiffDetails = async () => {
      try {
        if (diff) {
          const sire = diff.effect.sire ? await getDogById(diff.effect.sire, {accessToken}) : null;
          const dam = diff.effect.dam ? await getDogById(diff.effect.dam, {accessToken}) : null;
          const owners = diff.effect.owners ? await Promise.all(diff.effect.owners.map((owner) => getUserById(owner, {accessToken}))) : [];
          const breeder = diff.effect.breeder ? await getUserById(diff.effect.breeder, {accessToken}) : null;
          setPopulatedDiff({
            ...diff,
            effect: {
              ...diff.effect,
              sire,
              dam,
              owners,
              breeder,
            },
          });
        }
      } catch (error) {
        setSnackbarState({
          message: `Error fetching diff details: ${error.message}`,
          severity: "error",
          open: true,
        });
      }
    };

    fetchDiffDetails();
  }, [dog]);

  // What type of dog is this: registered, pending, or treedog?
  const dogType = getDogType(dog);

  // Form handling
  const formMethods = useForm({ mode: "all" });
  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting, isDirty },
  } = formMethods;
  // const formdata = watch();

  // State to be shared between components
  const [sharedState, setSharedState] = useState({
    declaredBreed: null,
    declaredMix: null,
    declaredType1: null,
    declaredType2: null,
  });

  // Include the state and its setter function in the formMethods object
  const extendedFormMethods = {
    ...formMethods,
    sharedState,
    setSharedState,
  };

  // Initialize some hidden form values
  useEffect(() => {
    setValue("extRegAncestry", initialDog?.regOther ? regOtherToAncestry(dog.regOther) : null);
    setValue("selectedAncestryVerification", initialDog?.ancestry?.verification);
  }, []);
  

  // Handle form submit, call the parent components handleSubmit function that send the document to API patch
  const onSubmit = async (data) => {

    // Check if there are any items in the "delete" fields of attachmentsMod
    const hasDeletions =
      attachmentsMod.images.delete.length > 0 ||
      attachmentsMod.files.delete.length > 0 ||
      attachmentsMod.links.delete.length > 0 ||
      attachmentsMod.text.delete.length > 0;

    if (hasDeletions) {
      const confirmDelete = window.confirm("Are you sure you want to delete attachments?");
      if (!confirmDelete) {
        setLoading(false);
        return;
      }
    }

    data.countryOfOrigin = data.countryOfOrigin?.code;
    if (data.owners) {
      data.owners = data.owners.map((owner) => owner._id);
    }

    data.breeder = data.breeder?._id;
    data.sire = data.sire?._id;
    data.dam = data.dam?._id;

    if (data.dateOfBirth) {
      const parsedDate = dayjs(data.dateOfBirth).startOf('day');
      if (parsedDate.isValid()) {
        data.dateOfBirth = parsedDate.format('MM/DD/YYYY');
      } else {
        setSnackbarState({
          message: t("Invalid date format (birth): ") + data.dateOfBirth,
          severity: "error",
          open: true,
        });
        return;
      }
    }

    if (data.dateOfDeath) {
      const parsedDate = dayjs(data.dateOfDeath).startOf('day');
      if (parsedDate.isValid()) {
        data.dateOfDeath = parsedDate.format('MM/DD/YYYY');
      } else {
        setSnackbarState({
          message: t("Invalid date format (death): ") + data.dateOfBirth,
          severity: "error",
          open: true,
        });
        return;
      }
    }

    // Fix ancestry bug - TODO figure out why this happens
    if (data.ancestry && data.ancestry.verification === null) {
      data.ancestry = null;
    }
    if (data.ancestry) {
      data.ancestry.breeds = data.ancestry.breeds.filter((entry) => entry.breed);
    }

    // Submit list of IDs from declaredTypes
    const declaredTypeObjs = [data.declaredBreed, data.declaredMix, data.declaredType1, data.declaredType2];
    data.declaredTypes = declaredTypeObjs.filter(Boolean).map((type) => type._id);

    // Massage coat data
    const modifiers = []
      .concat(data.alocusmodifiers || [])
      .concat(data.merlemodifiers || [])
      .concat(data.othermodifiers || []);
    const markings = []
      .concat(data.whitemarkings || [])
      .concat(data.spots || [])
      .concat(data.othermarkings || []);
    const coatQualities = []
      .concat(data.coatqualities || [])
      .concat(data.coatcurliness || []);
    
    data.coat = {
      color: data.color,
      markings: markings,
      modifiers: modifiers,
      type: data.coattype,
      qualities: coatQualities,
    };

    // Organization updates
    data.organizations = initialDog.organizations.map(org => {
      if (org.organization.prefix === organizationPrefix) {
        return {
          ...org,
          regClass: data.regClass,
          regType: data.regType,
        };
      }
      return org;
    });

    // Handle the submit
    try {
      await emitHandleSubmit(accessToken, data, initialDog?._id);
    } catch (err) {
      setSnackbarState({
        message: t("Error submitting form: ") + err.message,
        severity: "error",
        open: true,
      });
      setLoading(false);
      return;
    }
  };

  // Handle deletion of a dog/tempdog, prompt for confirm before the api call is done,
  // after removal, redirect user to listing
  const handleDelete = async () => {
    if (
      confirm(
        `Are you sure you want to delete ${initialDog?.name ? initialDog.name : "this dog"}? This process can NOT be undone.`
      )
    ) {
      await deleteDog(initialDog._id, { accessToken }).then((res) => {
        if (res.error) {
          throw res;
        } else {
          alert(`${res.name ? res.name : "tree dog"} has been removed.`);
          window.location.replace(`/${organizationPrefix}/admin/all${dogType}s`);
        }
      });
    } else {
      alert(`Cancelled deletion`);
    }
  };

  const handleAcceptDog = () => {
    if (
      confirm(
        "Are you sure you want to accept this dog? It is going to be published in the public database."
      )
    ) {
      setLoading(true);
      registerDog(initialDog._id, { accessToken }).then((res) => {
        if (res.error) {
          setLoading(true);
          throw res;
        } else {
          setLoading(true);
          alert("Dog has been accepted and is public.");
          window.location.replace(redirect);
        }
      });
    } else {
      alert(`Cancelled accepting dog`);
    }
  };

  const handleAcceptDiff = () => {
    setLoading(true);
    acceptDiff(populatedDiff._id, { accessToken }).then((res) => {
      if (res.error) {
        setLoading(true);
        throw res;
      } else {
        setLoading(true);
        alert("Update has been accepted.");
        window.location.replace(redirect);
      }
    });
  };

  const handleRejectDiff = () => {
    setLoading(true);
    rejectDiff(populatedDiff._id, { accessToken }).then((res) => {
      if (res.error) {
        setLoading(true);
        throw res;
      } else {
        setLoading(true);
        alert("Update has been rejected.");
        window.location.replace(redirect);
      }
    });
  };

  const [expanded, setExpanded] = useState(false);

  const handleExpandChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (!initialDog || (diff && !populatedDiff)) {
    setLoading(true);
    return <div>Loading...</div>;
  } else {
    setLoading(false);
  }

  return (
    <FormProvider {...extendedFormMethods}>
      <form onSubmit={handleSubmit(async (data) => await onSubmit(data))}>
      <Grid container direction="row" spacing={3} alignItems="flex-start">
          <Grid item xs={12}>
            <FormHeader
              action="edit"
              formType={dogType}
              data={initialDog}
            />
            <AcceptButton
              formType={diff ? "diff" : dogType}
              display={diff || initialDog?.pendingRegistration}
              handleAccept={diff ? handleAcceptDiff : handleAcceptDog}
              />
            {diff && (
            <AcceptButton
              action="reject"
              formType={diff ? "diff" : dogType}
              display={diff || initialDog?.pendingRegistration}
              handleAccept={handleRejectDiff}
            /> )}
            {initialDog?.organizations?.map((orgAndMore, index) => (
              <IconButton key={index}
                href={`/${orgAndMore.organization.prefix}/dog/${orgAndMore.regNumber || initialDog._id}`}
                target="_blank"
              >
                <Launch /> {orgAndMore.organization.prefix}
              </IconButton>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="right" sx={{ mb: 2 }}>
              <Typography variant="h5">
                {initialDog?.name ? `${initialDog.name}, ` : ""}{initialDog?.regNumber || "No reg. no"}
              </Typography>
            </Divider>
          </Grid>

          {populatedDiff && (          
          <Grid item xs={12}>
            <Typography variant="h5">This dog has a requested update.</Typography>
            <Typography variant="body">Please review the changes below and accept or reject them. The dog is not editable until the update is resolved.</Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary="Submitted"
                  secondary={dayjs(populatedDiff.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                />
              </ListItem>
              <ListItem>
              <ListItemText
                primary="Submitted by"
                secondary={
                  populatedDiff.user
                    ? `${populatedDiff.user.name || "Name withheld"} (${populatedDiff.user.email || "Email withheld"})`
                    : "Name and email withheld"
                }
              />
              </ListItem>
            </List>
          </Grid>
          )}

          <fieldset disabled={diff}>

          <Grid item xs={12}>
            <Accordion
              expanded={expanded === "dogInfo"}
              onChange={handleExpandChange("dogInfo")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="dogInfo-content"
                id="dogInfo-header"
              >
                <Typography variant="h5">Dog Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <EditDogInformation dog={initialDog} diff={populatedDiff} formMethods={formMethods} />
              </AccordionDetails>
            </Accordion>

            {  compareRole(currentUser.role, "manager") && (

            <Accordion
              expanded={expanded === "dogRegistrationInfo"}
              onChange={handleExpandChange("dogRegistrationInfo")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="dogRegistrationInfo-content"
                id="dogRegistrationInfo-header"
              >
                <Typography variant="h5">Registration Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <EditDogRegistrationInformation dog={initialDog} diff={populatedDiff} formMethods={formMethods} />
              </AccordionDetails>
            </Accordion>
            )}

          {  compareRole(currentUser.role, "manager") && (

            <Accordion
              expanded={expanded === "dogAttachments"}
              onChange={handleExpandChange("dogAttachments")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="dogAttachments-content"
                id="dogAttachments-header"
              >
                <Typography variant="h5">Attachments</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <EditDogAttachments dog={initialDog} diff={populatedDiff} formMethods={formMethods} />
              </AccordionDetails>
            </Accordion>
          )}

{/*
            <Accordion
              expanded={expanded === "dogTesting"}
              onChange={handleExpandChange("dogTesting")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="dogTesting-content"
                id="dogTesting-header"
              >
                <Typography variant="h5">Health & Genetic Test Results</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <EditDogTesting dog={initialDog} formMethods={formMethods} />
              </AccordionDetails>
            </Accordion>
*/ }
            <Accordion
              expanded={expanded === "dogBreed"}
              onChange={handleExpandChange("dogBreed")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="dogBreed-content"
                id="dogBreed-header"
              >
                <Typography variant="h5">Breed Ancestry</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <EditDogBreed dog={initialDog} diff={populatedDiff} formMethods={formMethods} />
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "dogAppearance"}
              onChange={handleExpandChange("dogAppearance")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="dogAppearance-content"
                id="dogAppearance-header"
              >
                <Typography variant="h5">Appearance</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <EditDogAppearance dog={initialDog} diff={populatedDiff} formMethods={formMethods} />
              </AccordionDetails>
            </Accordion>

            { /* <EditDogExtras dog={initialDog} formMethods={formMethods} /> */ }

          { /*
          <Grid item xs={12}>
            <JsonPreviewWidget formdata={formdata} />
          </Grid>
          */ }
          
          <Grid item xs={12} sx={{ mt: 4 }}>
            <FormButtons
              action="edit"
              handleDelete={handleDelete}
              isSubmitting={diff || isSubmitting}
              isDirty={!diff && isDirty}
              errors={errors}
            />
          </Grid>
        </Grid>
        </fieldset>
        </Grid>
      </form>
    </FormProvider>
  );
}
