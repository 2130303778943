import React from "react";
import MyDogsList from "./myDogsList";
import { useTranslation } from "react-i18next";
import { ArrowDropDown } from "@mui/icons-material";
import { Button, SwipeableDrawer } from "@mui/material";
export default function DrawerMenu({ dogs, handleSelection, open, setOpen }) {
  const { t } = useTranslation();

  const handleOpen = () => {
    if (open === false) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        fullWidth
        size="large"
        variant="contained"
        endIcon={<ArrowDropDown />}
        onClick={handleOpen}
      >
        {t("myDogs")}
      </Button>

      <SwipeableDrawer
        variant="temporary"
        anchor="left"
        open={open}
        onClose={handleOpen}
        onOpen={handleOpen}
      >
        <MyDogsList dogs={dogs} handleSelection={handleSelection} />
      </SwipeableDrawer>
    </>
  );
}
