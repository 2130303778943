import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { loadingState, snackBarState } from "recoil/globalStates";
import { attachmentsModState, adminDiffByParentIdState, selectedDocByIdState } from "recoil/adminStates";

// Routes
import { updateDog } from "api/adminRoutes";
// import { addAttachment, deleteAttachment } from "api/privateRoutes";
// import { getAttachments } from "api/adminRoutes";
// import { getAttachments } from "api/publicRoutes";

// Components
import DogEditForm from "components/formComponents/dogEditForm";
import { AdminNotFound } from "components/adminDashboardComponents/adminNotFound";

function AdminDogView() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [attachmentsMod, setAttachmentsMod] = useRecoilState(attachmentsModState);
  const dog = useRecoilValue(selectedDocByIdState("dog"));
  const { id, organizationPrefix } = useParams();

  const diff = useRecoilValue(adminDiffByParentIdState("dog"));

  useEffect(() => {
    // Is this diff still relevant, or has dog been updated since it was created?
    if (diff && dayjs(diff.createdAt).isBefore(dayjs(dog.updatedAt))) {
      setSnackbarState({
        message: t("This dog has an update request which is out of date. Recommended practice is to reject it and ask the user to submit a new one."),
        severity: "error",
        open: true,
      });
    }
  }, [diff, dog]);

    const handleSubmit = async (accessToken, updatedDog, id) => {
    setLoading(true);

    const updateResponse = await updateDog(id, updatedDog, attachmentsMod, { accessToken });
    if (updateResponse?.error) {
      setSnackbarState({
        message: "Dog not updated: " + updateResponse.message,
        severity: "error",
        open: true,
      });
      setLoading(false);
      return;
    }

    setLoading(false);
    setSnackbarState({
      message: "Dog updated successfully",
      severity: "success",
      open: true,
    });

    // Reset the form
    window.location.replace(`/${organizationPrefix}/admin/dog/${id}`);
    // resetFormData();
    // reset();
    
  };


  return dog ? (
    
    <DogEditForm
      dog={dog}
      emitHandleSubmit={handleSubmit}
    />
  ) : (
    <AdminNotFound docType="Dog" />
  );
}

export default AdminDogView;
