import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { loadingState, snackBarState } from "recoil/globalStates";
import { currentDogOrgState, 
  currentDogState,
  getOrgByPrefixState,
 } from "recoil/publicStates";

// MUI
import { Paper, Typography } from "@mui/material";

// Local
import DogEditForm from "components/formComponents/dogEditForm";
import { requestUpdateDog } from "api/privateRoutes";
import { getOrgAndMore } from "constants/helpers";

function DogEdit() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const { id, organizationPrefix } = useParams();

  const organization = useRecoilValue(getOrgByPrefixState(organizationPrefix));

  if (!organization) {
    return (
      <Paper square sx={{ p: 2 }}>
        <Typography variant="body">{t("No organization found with abbreviation")} <i>{organizationPrefix}</i></Typography>
      </Paper>
    );
  }

  // If id > 12 characters, it is a uuid and we use currentDogState
  // Otherwise it is a regNumber and we use currentDogState with organization
  const dog = id.length > 12 ? useRecoilValue(currentDogState(id)) : useRecoilValue(currentDogOrgState({id: id, organization: organization}));
  
  if (!dog) {
    return (
      <Paper square sx={{ p: 2 }}>
        <Typography variant="body">{t("No dog found in this organization with number")} <i>{id}</i></Typography>
      </Paper>
    );
  }

  const orgWithReg = getOrgAndMore(dog, organization);
  if ( ! orgWithReg) {
    return (
      <Paper square sx={{ p: 2 }}>
        <Typography variant="body">{t("No dog found in this organization with ID")} <i>{id}</i></Typography>
      </Paper>
    );
  }

  const handleSubmit = async (accessToken, updatedDog, id) => {
    setLoading(true);

    // TODO - attachments
    const attachmentsMod = null;

    const updateResponse = await requestUpdateDog(id, updatedDog, attachmentsMod, { accessToken });

    if (updateResponse?.error) {
      setSnackbarState({
        message: "Error submitting update request: " + updateResponse.message,
        severity: "error",
        open: true,
      });
      setLoading(false);
      return;
    }

    setLoading(false);
    alert ("Dog update submitted for approval.");

    // Return to list of my dogs
    window.location.replace(`/mydogs?dogId=${dog._id}`);
    
  };


  return dog ? (
    
    <DogEditForm
      dog={dog}
      emitHandleSubmit={handleSubmit}
    />
  ) : (
    <Typography variant="h6" color="error">
      {t("No dog found with ID ...?")}
    </Typography>
  );
}

export default DogEdit;
