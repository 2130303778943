import React, { useEffect, useRef, useState, Suspense } from "react";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "recoil/globalStates";
import { getOrgByIdState } from "recoil/publicStates";
import {
  accessTokenState, 
  loadingState,
  snackBarState } from "recoil/globalStates";

// MUI
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Table, TableBody, TableCell, TableContainer, TableRow,
  Typography,
} from "@mui/material";
import {
  Article as ArticleIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Launch as LaunchIcon,
  QueryStats as QueryStatsIcon,
} from "@mui/icons-material";

// Local
import { getDogsPendingDiffs } from "api/privateRoutes";
import CertificateTemplate from "../registrationCertificates/certificateTemplate";
import PhotoModal from "../dogProfileComponents/PhotoModal";
import OwnershipChangeForm from "./ownershipChangeForm";
import OwnershipFormModal from "./formModal";
import Loader from "elements/Loader";

import { compareRole, displayDate, getKruValueLabel } from "constants/helpers";
import { rejectDiff } from "api/privateRoutes";

export default function DogView({ selectedDog }) {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const user = useRecoilValue(userState);
  const [diffs, setDiffs] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [loadingDiffs, setLoadingDiffs] = useState(false);
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const componentRef = useRef(null);
  const error = "-";

  useEffect(() => {
    const fetchDiffs = async () => {
      setLoadingDiffs(true);
      try {
        const pendingDiffs = await getDogsPendingDiffs(selectedDog._id, { accessToken });
        setDiffs(pendingDiffs);
      } catch (error) {
        setSnackBar({
          open: true,
          severity: "error",
          message: t("Failed to fetch pending updates: ") + error,
        });
      } finally {
        setLoadingDiffs(false);
      }
    };
    fetchDiffs();
  }, [selectedDog]);
  
  if (diffs?.length > 1) {
    setSnackBar({
      open: true,
      severity: "warning",
      message: t("This dog has multiple pending edits, which should not happen."),
    });
  }

  if (diffs?.[0]?.createdAt < selectedDog.createdAt) {
    setSnackBar({
      open: true,
      severity: "warning",
      message: t("This dog has edits that are newer than its pending edit. You should delete and resubmit the pending edit."),
    });
  }


  const handleDeleteDiff = async (diffId) => {

    const confirmDelete = window.confirm("Are you sure you want to remove this update request?");
    if (!confirmDelete) {
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const response = await rejectDiff(diffId, {accessToken});
      setLoading(false);
      if (response.status === "rejected") {
        alert("Update has been rejected.");
      } else {
        console.log("Error rejecting update. Diff status:", t(response.status));
        alert("Error in rejecting update.");
      }
    } catch (error) {
      setSnackBar({
        open: true,
        severity: "error",
        message: t("Edit request removal failed: ") + t(error.message),
      });
    }
    setDiffs([]);
    setLoading(false);
  };

  const handlePrint = (componentRef, regNumber) => useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `FDDregistration-${regNumber}`,
  });

  const handleOwnershipModal = () => {
    if (modalOpen === true) {
      if (confirm(t("confirmModalCloseText"))) {
        setModalOpen(false);
      }
    } else {
      setModalOpen(true);
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <Grid
        container
        spacing={{ xs: 2 }}
        sx={{ p: { xs: 2, sm: 4 } }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="h2">{selectedDog?.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <PhotoModal dog={selectedDog} />
        </Grid>
        <Grid item xs={12} sm={8}>
            <ListItem>
              <ListItemIcon sx={{ pr: 2 }}>
                <ListItemText primary={`${t("FDD number")}`} />
              </ListItemIcon>
              <ListItemText primary={t(selectedDog?.regNumber)} />
            </ListItem>
            <Divider variant="middle" />
            <ListItem>
              <ListItemIcon sx={{ pr: 2 }}>
                <ListItemText primary={`${t("microchipNumber")}`} />
              </ListItemIcon>
              <ListItemText primary={t(selectedDog?.microchipNumber)} />
            </ListItem>
            <Divider variant="middle" />
            <ListItem>
              <ListItemIcon sx={{ pr: 2 }}>
                <ListItemText primary={`${t("dob")}`} />
              </ListItemIcon>
              <ListItemText
                primary={displayDate(selectedDog?.dateOfBirth)} />
            </ListItem>
            <Divider variant="middle" />
            <ListItem>
              <ListItemIcon sx={{ pr: 2 }}>
                <ListItemText primary={`${t("sex")}`} />
              </ListItemIcon>
              <ListItemText primary={t(selectedDog?.sex)} />
            </ListItem>
        </Grid>
      </Grid>

      {selectedDog?.organizations?.map((orgWithReg, index) => {
        const showCert = orgWithReg.regNumber ? true : false;
        const printHandler = handlePrint(componentRef, orgWithReg.regNumber);
        const orgId = orgWithReg.organization;
        let organization = null;
        if (typeof orgId === "string") {
          organization = useRecoilValue(getOrgByIdState(orgWithReg.organization));
        } else {
          organization = useRecoilValue(getOrgByIdState(orgWithReg.organization._id));
        }
        return (
           <>
            <TableContainer key={index} component={Paper}>
              <Table>
                <TableBody>
                  <TableRow hover>
                    <TableCell variant="head">{t("registry")}</TableCell>
                    <TableCell>
                      {organization?.name}
                    </TableCell>
                    <TableCell variant="head">{t("registrationClass")}</TableCell>
                    <TableCell>
                      {getKruValueLabel(orgWithReg.regClass, lang) || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell variant="head">{t("registrationNumber")}</TableCell>
                    <TableCell>{orgWithReg.regNumber || error}</TableCell>
                    <TableCell variant="head">{t("registrationDate")}</TableCell>
                    <TableCell colSpan={3}>
                      {selectedDog.createdAt
                        ? dayjs(selectedDog.createdAt).format("MM.DD.YYYY")
                        : error}
                    </TableCell>
                  </TableRow>
                  <TableRow hover></TableRow>
                  <TableRow hover></TableRow>
                  {/*<TableRow hover>
            {dog.regOther?.length ? (
              <>
                <TableCell variant="head">
                  {t("externalRegistries")}
                </TableCell>
                <TableCell colSpan="3">
                  {dog?.regOther
                    .map((reg) => Object.values(reg).join(" - "))
                    .join(", ")}
                </TableCell>
              </>
            ) : null}
          </TableRow>
          <TableRow hover>
            <TableCell variant="head">{t("certificates")}</TableCell>
            <TableCell>
              {certs
                ? certs.map((cert) => cert.certificate.name).join(", ")
                : error}
            </TableCell>
            </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
            
            <List>
              <ListItemButton href={`/${organization?.prefix}/dog/${orgWithReg.regNumber ? orgWithReg.regNumber : selectedDog._id}`} target="_blank">
                <ListItem>
                  <ListItemIcon>
                    <LaunchIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h6">{t("showInDatabase")}</Typography>
                    }
                  />
                </ListItem>
              </ListItemButton> 
              {loadingDiffs ? (
                <ListItemButton>
              <ListItem>
                <ListItemIcon>
                  <QueryStatsIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6">{t("Searching for pending updates")}...</Typography>
                  }
                />
              </ListItem>
              </ListItemButton>
              ) : (
              diffs && diffs.length == 1 ? (
                <ListItemButton onClick={() => handleDeleteDiff(diffs[0]._id)}>
                <ListItem>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h6">{t("Pending edit request")} {dayjs(diffs[0].createdAt).format('YYYY-MM-DD')}</Typography>
                    }
                  />
                </ListItem>
              </ListItemButton>
              ) : compareRole(user.role, "manager") ? (
                <ListItemButton href={`/${organization?.prefix}/admin/dog/${selectedDog._id}`}>
                  <ListItem>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="h6">{t("Edit")}</Typography>
                      }
                    />
                  </ListItem>
                </ListItemButton>
              ) : (
                <ListItemButton href={`/${organization?.prefix}/dog/edit/${orgWithReg.regNumber ? orgWithReg.regNumber : selectedDog._id}`}>
                  <ListItem>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="h6">{t("Submit edit request")}</Typography>
                      }
                    />
                  </ListItem>
                </ListItemButton>
                  ))}
              { showCert && (
                <>
              <Divider variant="middle" />
              <ListItemButton onClick={printHandler}>
                <ListItem>
                  <ListItemIcon>
                    <ArticleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        <Stack direction="row" alignItems="center">
                          <Typography variant="h6">
                            {t("downloadDogsRegistrationCertificate")}
                          </Typography>
                        </Stack>
                      </>
                    }
                  />
                </ListItem>
              </ListItemButton>
              </>
              )}
            </List>
            <Box sx={{ display: "none" }}>
              <CertificateTemplate dog={selectedDog} orgWithReg={orgWithReg} ref={componentRef} />
            </Box>
          </>
      );})}
      
      <OwnershipFormModal open={modalOpen} onClose={handleOwnershipModal}>
        <OwnershipChangeForm
          id={selectedDog._id}
          setModalOpen={setModalOpen}
          handleModalClose={handleOwnershipModal}
        />
      </OwnershipFormModal>
      </Suspense>
      );
    }
    