import React, { useState, useEffect } from "react";
import {
  Controller,
  get,
  useFieldArray,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

// Recoil
import {
  constSelector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import {
  kruValuesState,
  loadingState,
  userState,
} from "recoil/globalStates";

// MUI
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Delete, Add } from "@mui/icons-material";

// Local
import {
  getDogType,
  getBreeds,
  getNameByLang,
  getOrgAndMoreByPrefix,
  getRegistries,
  updateAncestry } from "constants/helpers";

export default function EditDogRegistrationInformation({ dog, diff, formMethods }) {
  const [loading, setLoading] = useRecoilState(loadingState);
  const { organizationPrefix } = useParams();

  const { isAuthenticated } = useAuth0();
  const currentUser = useRecoilValue(
    isAuthenticated ? userState : constSelector(null)
  );

  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const kruValues = useRecoilValue(kruValuesState);

  const [initialDog, setInitialDog] = useState(dog);
  const orgAndMore = getOrgAndMoreByPrefix(dog, organizationPrefix);
  const org = orgAndMore?.organization;

  // What type of dog is this: registered, pending, or tree?
  const dogType = getDogType(dog);
  let classList = kruValues
    .filter((i) => i.category === "reg.classes" && i._id !== "reg.classes.tree");
  if (dogType === "treedog") {
    classList = kruValues
      .filter((i) => i._id === "reg.classes.tree");
  }

  const {
    control,
    setValue,
    watch,
    formState: { errors, isSubmitting, isDirty },
  } = formMethods;

  const {
    append: appendRegOther,
    fields: regOtherFields,
    remove: removeRegOther,
  } = useFieldArray({ control, name: "regOther" });

  const formdata = watch();
  const regType = formdata.regType;
  const regOther = formdata.regOther;
  
  const breeds = getBreeds(kruValues, language);
  const registries = getRegistries(kruValues, language);

  // set the regOther array from the initial dog since useFieldArray doesnt allow
  // setting a default and useForm defaults dont allow variables as defaults
  useEffect(() => {
    if (initialDog?.regOther) {
      const mappedRegOther = initialDog.regOther.map((reg) => ({
        ...reg,
        regRegistry: registries.find((r) => r._id === reg.regRegistry),
        regBreed: breeds.find((b) => b._id === reg.regBreed),
      }));
  
      setValue("regOther", mappedRegOther);
    }
  }, [initialDog]);

 // add new object to regOther array in the form state
 const addRegOther = () => {appendRegOther({});};

 if (loading) return <Typography variant="body">Loading...</Typography>;

return (
  <>
    <Grid container direction="row" spacing={3} alignItems="flex-start" sx={{ width: '100%' }}>
      <Grid item xs={12} md={4}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
            {org?.name}
        </Typography>
        <Typography variant="body">{orgAndMore?.regNumber || "Not registered"}</Typography><br />
        { /*
        <IconButton onClick={() => handleOrganizationDelete(org, formMethods)}>
          <Delete />
        </IconButton> */}
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl fullWidth>
          <InputLabel>{t("registrationType")} *</InputLabel>
          <Controller
            name="regType"
            control={control}
            defaultValue={orgAndMore?.regType || "reg.types.individual"}
            rules={{
              required: "Required",
            }}
            render={({ field: { ref, name, ...field } }) => (
              <Select
                {...field}
                fullWidth
                required
                label="Registration type"
              >
                {kruValues
                  .filter((i) => i.category === "reg.types")
                  .map((value) => (
                    <MenuItem key={value._id} value={value._id}>
                      {value.en.name}
                    </MenuItem>
                  ))}
              </Select>
            )}
          />
          <FormHelperText error={errors?.regType}>
            {errors?.regType?.message}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl fullWidth>
          <InputLabel>{t("registrationClass")} *</InputLabel>
          <Controller
            name="regClass"
            control={control}
            defaultValue={orgAndMore?.regClass || "reg.classes.limited"}
            rules={{
              required: "Required",
            }}
            render={({ field: { ref, name, ...field } }) => (
              <Select
                {...field}
                fullWidth
                required
                label="Registration class"
              >
                {classList
                  .map((value) => (
                    <MenuItem key={value._id} value={value._id}>
                      {getNameByLang(value, language)}
                    </MenuItem>
                  ))}
              </Select>
            )}
          />
          <FormHelperText error={errors?.regClass}>
            {errors?.regClass?.message}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>

      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography variant="h5">{t("External registrations")}</Typography>
        </Divider>
      </Grid>
      <Grid item xs={12} md={12}>
      {regOtherFields.length > 0 ? (
          regOtherFields.map((field, i) => (
            <React.Fragment key={field.id}>
              <Stack
                direction={{ xs: "column", md: "row" }}
                alignItems={"flex-start"}
                spacing={2}
                p={2}
              >
                <Typography variant="body">{i + 1}.</Typography>                        
                <Controller
                  name={`regOther[${i}].regRegistry`}
                  control={control}
                  defaultValue={regOther[i]?.regRegistry || ""}
                  rules={{
                    required: t("required"),
                    minLength: 1,
                    maxLength: { value: 100, message: t("Max 100 characters") },
                  }}
                  render={({ field: { onChange, ...props } }) => (
                    <Autocomplete
                      fullWidth
                      freeSolo
                      label="Registry"
                      options={registries}
                      getOptionLabel={(option) =>
                        option[language] ? option[language].name : ""
                      }
                      onChange={(e, data) => onChange(data)}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                        }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          required
                          label={t("Registry")}
                          InputProps={{
                            ...params.InputProps,
                          }}
                      error={errors?.regOther?.[i]?.regRegistry}
                      helperText={errors?.regOther?.[i]?.regRegistry && errors?.regRegistry?.[i]?.regRegistry?.message}
                      />
                      )}
                      {...props}
                    />
                  )}
                />
                
                  <Controller
                    name={`regOther[${i}].regNumber`}
                    control={control}
                    defaultValue={
                      initialDog?.regOther?.[i]?.regNumber || ""
                    }
                    rules={{
                      required: t("required"),
                      minLength: 1,
                      maxLength: { value: 100, message: t("Max 100 characters") },
                    }}            
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        required
                        label="Registration number"
                        error={errors?.regOther?.[i]?.regNumber}
                        helperText={errors?.regOther?.[i]?.regNumber && errors?.regOther?.[i]?.regNumber?.message}
                      />
                    )}
                  />

                  <Controller
                    name={`regOther[${i}].regBreed`}
                    control={control}
                    defaultValue={regOther[i]?.regBreed || ""}
                    render={({ field: { onChange, ...props } }) => (
                      <Autocomplete
                        fullWidth
                        freeSolo
                        label="Registered breed"
                        options={breeds}
                        getOptionLabel={(option) =>
                          option[language] ? option[language].name : ""
                        }
                        onChange={(e, data) => {
                          updateAncestry("extReg", {breed: data, percentage: 100}, i, formMethods);
                          onChange(data);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option._id === value._id
                        }
                        renderInput={(params) => (
                          <TextField {...params} label={t("Breed")} />
                        )}
                        {...props}
                      />
                    )}
                  />

                <IconButton
                  onClick={() => {
                    if (
                      confirm(
                        t("Are you sure you want to remove this external registration?")
                      )
                    ) {
                      removeRegOther(i);
                      updateAncestry("extReg", null, i);
                    }
                  }}
                >
                  <Delete />
                </IconButton>
              </Stack>
            </React.Fragment>
          ))
        ) : (
          <Typography variant="body">No external registries</Typography>
        )}

          </Grid>
          <Grid item xs={6} md={3}>
          <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={addRegOther}
              sx={{ float: "right", mt: 2 }}
            >
              Add external registry number
            </Button>
          </Grid>
      </>
  );
}
