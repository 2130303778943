import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAuth0 } from "@auth0/auth0-react";

// Recoil
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  accessTokenState,
  kruValuesState,
  loadingState,
  snackBarState,
} from "recoil/globalStates";

// MUI
import {
  Autocomplete,
  Box,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

// Local
import { getBreeds, getMixes, getTypes, getNameByLang, getOriginalValue } from "constants/helpers";

function editDogBreed({ dog, diff, formMethods }) {
  const [initialDog, setInitialDog] = useState(dog);
  const [initialDiff, setInitialDiff] = useState(diff);
  const { isAuthenticated } = useAuth0();
  
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  
  const accessToken = useRecoilValue(accessTokenState);
  const kruValues = useRecoilValue(kruValuesState);
  const setLoading = useSetRecoilState(loadingState);

  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [loadingOverlayState, setLoadingOverlayState] =
    useRecoilState(loadingState);

  const theme = useTheme();
  
  const { clearErrors, control, setValue, watch, formState: { errors } } = formMethods;
  const { sharedState, setSharedState } = formMethods;
  const formdata = watch();

  const declaredBreed = formdata.declaredBreed;
  const declaredMix = formdata.declaredMix;
  const declaredType1 = formdata.declaredType1;
  const declaredType2 = formdata.declaredType2;
  const ancestryError = formdata.ancestryError;
  const extRegAncestry = formdata.extRegAncestry;
  const selectedAncestryVerification = formdata.selectedAncestryVerification;

  const breeds = getBreeds(kruValues, language);
  const mixes = getMixes(kruValues, language);
  const types = getTypes(kruValues, language);
 
  /* Lots of ancestry handling: */
  const regOtherToAncestry = (data) => {
    const extRegBreeds = data.map((reg) => reg.regBreed);
    const uniqueBreeds = [...new Set(extRegBreeds)];
    return {
      verification: "extReg",
      breeds: uniqueBreeds.map((b) => {
        return {
          breed: b,
          percentage: 100,
        };
      })
    };
  };

  const handleAncestryVerificationChange = (event) => {
    let verification = event.target.value;
    setValue("selectedAncestryVerification", verification);
  
    switch (verification) {
      case "extReg":
        setValue("ancestry", extRegAncestry);
        setValue("ancestryError", validateAncestry(extRegAncestry)); // TODO try to move
        break;
      case "pedigree": // TODO
        // setSelectedAncestryVerification("pedigree");
        break;
      case "geneticTest": // TODO
        // setSelectedAncestryVerification("geneticTest");
        break;
      default:
        setValue("ancestry", null);
        setValue("ancestryError", null);
        break;
    }
  };
  
  const displayAncestry = (data) => {
    let currentAncestry = {...data};
    if (!currentAncestry || !currentAncestry.verification || currentAncestry.verification === "unverified") {
      return <p>{t("No ancestry defined.")}</p>;
    }

    // remove null breeds
    currentAncestry.breeds = currentAncestry.breeds.filter((entry) => entry.breed);

    return (
      <div>
        <List disablePadding>
          <ListItem>
            <>
            {currentAncestry.breeds.map((breed, index) => (
            <ListItemText key={`ancestrybreed.${index}`}
              primary={getNameByLang(breeds.find(b => b._id === breed.breed), language)}
              secondary={`${breed.percentage}%`}
            /> ))}
            </>
            </ListItem>
        </List>

       <Typography variant="caption" color="error">
          {ancestryError}
        </Typography>
      </div>
    );
  };

  const updateAncestry = (verification, data, index) => {
    let newAncestry = null;

    switch (verification) {
      case "extReg":
        newAncestry = updateExtRegAncestry(data, index);
        break;
      case "pedigree":
        setPedigreeAncestry(data);
        break;
      case "geneticTest":
        setGeneticTestAncestry(data);
        break;
      default:
        setSnackbarState({
          message: t("Ancestry verification " + verification + " not recognized."),
          severity: "error",
          open: true,
        });
        break;
    }

    if (selectedAncestryVerification === verification) {
      setValue("ancestryError", validateAncestry(newAncestry));
      setValue("ancestry", newAncestry);
    }
};

  const updateExtRegAncestry = (data, index) => {
    // extRegAncestry: { breeds: [{ breed: String, percentage: Number }] }
    let newAncestry = {...extRegAncestry};
    if (data) {
      newAncestry.breeds[index] = {
        breed: data.breed._id,
        percentage: data.percentage,
      };
    } else {
      newAncestry.breeds.splice(index, 1);
    }
    setExtRegAncestry(newAncestry);
    return newAncestry;
  };

  const validateAncestry = (ancestry) => {
    // If ancestry has no breeds, return : "Ancestry must have at least one breed."
    if (!ancestry || !ancestry.breeds || ancestry.breeds.length === 0) {
      return t("Ancestry must have at least one breed.");
    }


    // remove null breeds
    let ancestryBreeds = ancestry.breeds.filter((entry) => entry.breed);
    if (ancestryBreeds.length === 0) {
      return t("Ancestry must have at least one breed.");
    }

    // if the same breed is entered twice, then compare breed.percentage.
    // If the percentages are the same, filter out the duplicate breed.
    // If the percentages are different, return "Duplicate breed with different percentages."
    let ancestryBreedIds = ancestryBreeds.map((entry) => entry.breed);
    let uniqueBreedIds = [...new Set(ancestryBreedIds)];

    if (uniqueBreedIds.length < ancestryBreedIds.length) {
      let duplicateBreedIds = ancestryBreedIds.filter((breed, index) => ancestryBreedIds.indexOf(breed) !== index);

      // Extract the items from ancestryBreeds that match each breed._id in duplicateBreedIds
      duplicateBreedIds.forEach(duplicateBreedId => {
        let duplicateBreeds = ancestryBreeds.filter((breed) => breed.breed._id === duplicateBreedId);
        let breedPercentages = duplicateBreeds.map((b) => b.percentage);
        if (new Set(breedPercentages).size > 1) {
          return t("Ancestry contains breed with different percentages: " + duplicateBreedId);
        }
      });

      // Filter ancestryBreeds to have only one instance of each breed._id
      // (we trust the percentages are the same per breed at this point
      // so it doesn't matter which we take)
      ancestryBreeds = ancestryBreeds.filter((entry, index, self) => {
        return self.findIndex((b) => b.breed === entry.breed) === index;
      });
    }

    // If any breed percentage is < 0 or > 100, return "Breed percentage must be between 0 and 100."
    let invalidPercentages = ancestryBreeds.filter((breed) => breed.percentage < 0 || breed.percentage > 100);
    if (invalidPercentages.length > 0) {
      return t("Breed percentage must be between 0 and 100: " +
        invalidPercentages.map((breed) => `${breed.breed} (${breed.percentage}%)`).join(", "));
    }

    // If percentages all total to more than 100, return "Ancestry breeds cannot sum to more than 100."
    let sumPercentages = ancestryBreeds.reduce((sum, breed) => sum + breed.percentage, 0);
    if (sumPercentages > 100) {
      if (ancestry.verification === "extReg") {
        return t("Cannot verify ancestry if dog is registered as different breeds: " + 
          ancestryBreeds.map((breed) =>
            getNameByLang(breeds.find(b => b._id === breed.breed), language)
          ).join(", ")
        );
      }
      return t("Ancestry percentages cannot sum to more than 100%. They sum to " + sumPercentages + "%.");
    }

    // No errors!
    return null;
};


/* Lots of declared type stuff */
// Map dog.declaredTypes from kruValue ids to kruValue objects
const kruAncestry = kruValues.filter((value) => value.category === "ancestry");

const declaredTypesObjs = initialDog?.declaredTypes?.map((type) => kruAncestry.find((t) => t._id === type)) || [];
const [selectedDeclaredBreed, setSelectedDeclaredBreed] = useState(
  declaredTypesObjs.filter((type) => type._id.startsWith("breeds."))[0] || null
);

const [selectedDeclaredMix, setSelectedDeclaredMix] = useState(
  declaredTypesObjs.filter((type) => type._id.startsWith("mixes."))[0] || null
);
const [selectedDeclaredTypes, setSelectedDeclaredTypes] = useState(() => {
  const filteredTypes = declaredTypesObjs.filter((type) => type._id.startsWith("types."));
  while (filteredTypes.length < 2) {
    filteredTypes.push(null);
  }
  return filteredTypes;
});

const diffDeclaredTypesObjs = initialDiff?.effect?.declaredTypes?.map((type) => kruAncestry.find((t) => t._id === type)) || [];
const [diffSelectedDeclaredBreed, setDiffSelectedDeclaredBreed] = useState(
  diffDeclaredTypesObjs.filter((type) => type._id.startsWith("breeds."))[0] || null
);

const [diffSelectedDeclaredMix, setDiffSelectedDeclaredMix] = useState(
  diffDeclaredTypesObjs.filter((type) => type._id.startsWith("mixes."))[0] || null
);
const [diffSelectedDeclaredTypes, setDiffSelectedDeclaredTypes] = useState(() => {
  const filteredTypes = diffDeclaredTypesObjs.filter((type) => type._id.startsWith("types."));
  while (filteredTypes.length < 2) {
    filteredTypes.push(null);
  }
  return filteredTypes;
});

useEffect(() => {
  if (initialDiff) {
    setValue("declaredBreed", diffSelectedDeclaredBreed);
    setValue("declaredMix", diffSelectedDeclaredMix);
    setValue("declaredType1", diffSelectedDeclaredTypes[0]);
    setValue("declaredType2", diffSelectedDeclaredTypes[1]);
  } else {
    setValue("declaredBreed", selectedDeclaredBreed);
    setValue("declaredMix", selectedDeclaredMix);
    setValue("declaredType1", selectedDeclaredTypes[0]);
    setValue("declaredType2", selectedDeclaredTypes[1]);
  }
}, [initialDog]);

useEffect(() => {
  const invalid = validateDeclaredTypes();
}, [initialDog, declaredBreed, declaredMix, declaredType1, declaredType2]);

const validateDeclaredTypes = () => {
  const declared = [declaredBreed, declaredMix, declaredType1, declaredType2].filter(Boolean);
  const clearAllErrors = () => {
    clearErrors("declaredBreed");
    clearErrors("declaredMix");
    clearErrors("declaredType");
  };

  if (0 <= declared.length && declared.length <= 1) {
    clearAllErrors();
    return;
  }

  if (declared.length == 2 && [declaredType1, declaredType2].filter(Boolean).length == 2) {
     clearAllErrors();
    if (declaredType1._id === declaredType2._id) {
      setError("declaredType", { message: t("The two types must not be the same.") });
      return;
    }
    return;
  }

  const msg = t("Breed description must be a single breed OR a single mix OR 1-2 types.");
  declaredBreed ?
    setError("declaredBreed", { message: msg }) :
    clearErrors("declaredBreed");
  
  declaredMix ?
    setError("declaredMix", { message: msg }) :
    clearErrors("declaredMix");

  (declaredType1 || declaredType2) ?
    setError("declaredType", { message: msg }) :
    clearErrors("declaredType");
  };

  return (
    <>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography variant="h5">{t("Breed Description (Breed or Mix)")}</Typography>
        </Divider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box mb={2}>
          <Typography variant="body">{t('Select breed (for example, "Labrador Retriever" or "Goldendoodle"')}:</Typography>
        </Box>
        <Controller
          name="declaredBreed"
          control={control}
          defaultValue={selectedDeclaredBreed || ""}
          render={({ field: { onChange, ...props } }) => (
            <Autocomplete
              fullWidth
              freeSolo
              label="Breed"
              options={breeds}
              getOptionLabel={(option) =>
                option[language] ? option[language].name : ""
              }
              value={selectedDeclaredBreed || ""}
              onChange={(e, data) => {
                onChange(data);
              }}
              isOptionEqualToValue={(option, value) =>
                option._id === value._id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Breed")}
                  error={!!errors.declaredBreed}
                  helperText={errors.declaredBreed?.message}
                />
              )}
              {...props}
            />
          )}
        />
      </Grid>
    
      <Grid item xs={12} sm={6}>
        <Box mb={2}>
          <Typography variant="body">{t('OR, select mix (for example, "Labradoodle" or "Pomsky"')}:</Typography>
        </Box>
        <Controller
          name="declaredMix"
          control={control}
          defaultValue={selectedDeclaredMix || ""}
          render={({ field: { onChange, ...props } }) => (
            <Autocomplete
              fullWidth
              freeSolo
              label={t("Mix")}
              options={mixes}
              getOptionLabel={(option) =>
                option[language] ? option[language].name : ""
              }
              onChange={(e, data) => {
                onChange(data);
              }}
              isOptionEqualToValue={(option, value) =>
                option._id === value._id
              }
              renderInput={(params) => (
                <>
                <TextField
                  {...params}
                  label={t("Mix")}
                  error={!!errors.declaredMix}
                  helperText={errors.declaredMix?.message}
                />
                <FormHelperText>{getOriginalValue(initialDog, initialDiff, "declaredMix", language)}</FormHelperText>
                </>
              )}
              {...props}
            />
          )}
        />
      </Grid>
    
      <Grid item xs={12} sm={6}>
        <Box mb={2}>
          <Typography variant="body">
            {t("OR, for mixes without a widely-recognized name, select up to two types that best describe the breed content:")}
          </Typography>
        </Box>
        <Grid item xs={12}>
          <Box mb={2}>
            <Controller
              key="declaredType1"
              name="declaredType1"
              control={control}
              defaultValue={selectedDeclaredTypes[0] || ""}
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  freeSolo
                  label="Type"
                  options={types}
                  getOptionLabel={(option) =>
                    option[language] ? option[language].name : ""
                  }
                  onChange={(e, data) => {
                    onChange(data);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Type")}
                      error={!!errors.declaredType}
                      helperText={errors.declaredType?.message}
                    />
                  )}
                  {...props}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              key="declaredType2"
              name="declaredType2"
              control={control}
              defaultValue={selectedDeclaredTypes[1] || ""}
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  freeSolo
                  label="Type"
                  options={types}
                  getOptionLabel={(option) =>
                    option[language] ? option[language].name : ""
                  }
                  onChange={(e, data) => {
                    onChange(data);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  renderInput={(params) => (
                    <TextField {...params} label={t("Type")} />
                  )}
                  {...props}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography variant="h5">{t("Breed ancestry")}</Typography>
        </Divider>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body">
          <Controller
            name="ancestry"
            control={control}
            defaultValue={initialDog?.ancestry}
            rules={{
              validate: {
                ancestryErrorsCheck: () => ancestryError === null || ancestryError
              }
            }}
            render={({ field }) => (
              <>{displayAncestry(field.value)}</>
            )}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h5">{initialDog?.ancestry ? t("Modify") : t("Enter")} ancestry</Typography>
        <Controller
          name="ancestryVerification"
          defaultValue={selectedAncestryVerification || "unverified"}
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}
              value={selectedAncestryVerification || "unverified"}
              onChange={handleAncestryVerificationChange}
            >
              <FormControlLabel
                value="extReg"
                control={<Radio />}
                label={t("External registry")}
              />
              <FormControlLabel
                value="pedigree"
                control={<Radio />}
                label={t("Pedigree")}
                disabled
              />
              <FormControlLabel
                value="genetictest"
                control={<Radio />}
                label={t("Genetic test")}
                disabled
              />
              <FormControlLabel
                value="unverified"
                control={<Radio />}
                label={t("Unverified")}
              />
            </RadioGroup>
          )}
        />
      </Grid>
    </>  );
}

export default editDogBreed;
