import React, { createContext, Suspense } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// Recoil
import { useSetRecoilState } from "recoil";
import { snackBarState } from "recoil/globalStates";

// Authentication
import { AuthenticationGuard } from "./auth0";

// Import components
import { Container, Alert, Snackbar } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";

// Local components
import Navbar from "components/navigation/Navbar";
import Footer from "components/Footer";
import ScrollToTop from "components/ScrollToTop";
import { GlobalError } from "elements/alerts";
import { LoaderOverlay, LoaderOverlayMain } from "elements/Loader";
import { SnackbarManager } from "elements/SnackbarManager";

// Public Pages
import DogList from "pages/listViews/dogs";
import BreederList from "pages/listViews/breeders";
import LitterList from "pages/listViews/litters";
import OrganizationProfile from "pages/organizationProfile";
import DogEdit from "pages/dogEdit";
import DogProfile from "pages/dogProfile";
import BreederProfile from "pages/breederProfile";
import LitterProfile from "pages/litterProfile";
import Home from "pages/home";
import Privacy from "pages/privacy";
import Terms from "pages/terms";
import NotFound from "pages/notfound";
import Search from "components/search";

// Private Pages
import UserProfile from "pages/userProfile";
import MyDogs from "pages/usersOwnedDogs";
import BredDogs from "pages/usersBredDogs";
import DogRegistrationContainer from "pages/dogRegistration/dogRegistrationContainer";
import Registrations from "pages/usersRegistrations";
import Verify from "pages/verificationPage";
import LitterRegistrationContainer from "pages/litterRegistration/litterRegistrationContainer";
import LitterPaymentSuccess from "pages/litterRegistration/paymentSuccess";
import DogPaymentSuccess from "pages/dogRegistration/paymentSuccess";

// Admin Pages
import AdminDashboard from "pages/adminDashboard/views/dashboard/dashboard";
import AdminAllDogs from "pages/adminDashboard/views/allDogs";
import AdminView from "pages/adminDashboard/views/container";
import AdminDogView from "pages/adminDashboard/views/dog";
import AdminAllPendingDogs from "pages/adminDashboard/views/allPendingDogs";
import AdminAllUsers from "pages/adminDashboard/views/allUsers";
import AdminUserView from "pages/adminDashboard/views/user";
import AdminAllPendingLitters from "pages/adminDashboard/views/allPendingLitters";
import AdminLitterView from "pages/adminDashboard/views/litter";
import AdminAllLitters from "pages/adminDashboard/views/allLitters";
import AdminCompetitionResultView from "pages/adminDashboard/views/competitionresult";
import AdminAllCompetitionResults from "pages/adminDashboard/views/allCompetitionResults";
import AdminAllTreedogs from "pages/adminDashboard/views/allTreedogs";
import AdminAllOrganizations from "pages/adminDashboard/views/allOrganizations";
import AdminOrganizationView from "pages/adminDashboard/views/organization";
import AdminAllCertificates from "pages/adminDashboard/views/allCertificates";
import AdminCertificateView from "pages/adminDashboard/views/certificate";
import AdminAllResultproviders from "pages/adminDashboard/views/allResultproviders";
import AdminResultproviderView from "pages/adminDashboard/views/resultprovider";
import AdminPedigreeTool from "pages/adminDashboard/views/pedigreeTool";
import TreedogAdditionContainer from "pages/adminDashboard/views/treedogAddition/treedogAdditionContainer";
import TreedogAdditionSuccess from "pages/adminDashboard/views/treedogAddition/treedogAdditionSuccess";

// Contexts
export const UserContext = createContext();
export const KruValueContext = createContext();

export default function App() {

  const setSnackbarState = useSetRecoilState(snackBarState);

  const logError = (error) => {
    console.log("App caught an error", error);
    setSnackbarState({
      message: error.message,
      severity: "error",
      open: true,
    });
  };

  const Layout = () => (
    <>
      <Navbar />
      <Outlet />
    </>
  );

  return (
    <>
      <ErrorBoundary FallbackComponent={GlobalError} onError={logError}>
        <Suspense fallback={<LoaderOverlayMain />}>
          <Container
            disableGutters
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 0 } }}
          >
            <Routes>
              {/* PUBLIC ROUTES */}
              <Route element={<Layout />}>
                <Route path="/" element={<Home />} />
                <Route path="/search" element={<Search />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/:organizationPrefix/search" element={<Search />} />
                <Route path="/:organizationPrefix/home" element={<OrganizationProfile />} />
                <Route path="/:organizationPrefix/dogs" element={<DogList />} />
                <Route path="/:organizationPrefix/breeders" element={<BreederList />} />
                <Route path="/:organizationPrefix/litters" element={<LitterList />} />
                <Route path="/:organizationPrefix/breeder/:breederId" element={<BreederProfile />} />
                <Route path="/:organizationPrefix/dog/:id" element={<DogProfile />} />
                <Route path="/:organizationPrefix/dog/edit/:id" element={<DogEdit />} />
                <Route path="/:organizationPrefix/litter/:id" element={<LitterProfile />} />
                <Route path="*" element={<NotFound />} />

                {/* PRIVATE ROUTES */}
                
                <Route
                  path="/user"
                  element={<AuthenticationGuard component={UserProfile} />}
                /><Route
                path="/login"
                element={<AuthenticationGuard component={UserProfile} />}
              />
                <Route
                  path="/mydogs"
                  element={<AuthenticationGuard component={MyDogs} />}
                />
                <Route
                  path="/mylitters"
                  element={<AuthenticationGuard component={BredDogs} />}
                />
                <Route
                  path="/:organizationPrefix/litter-registration"
                  element={
                    <AuthenticationGuard
                      component={LitterRegistrationContainer}
                    />
                  }
                />
                <Route
                  path="/:organizationPrefix/litter-registration/:id/success"
                  element={
                    <AuthenticationGuard component={LitterPaymentSuccess} />
                  }
                />
                <Route
                  path="/:organizationPrefix/dog-registration"
                  element={<AuthenticationGuard component={DogRegistrationContainer} />}
                />
                <Route
                  path="/:organizationPrefix/dog-registration/:id/success"
                  element={<AuthenticationGuard component={DogPaymentSuccess} />}
                />
                <Route
                  path="/registrations"
                  element={<AuthenticationGuard component={Registrations} />}
                />
                <Route
                  path="/verify/:_id"
                  element={<AuthenticationGuard component={Verify} />}
                />
                {/* ADMIN DASHBOARD */}
                <Route
                  path="/:organizationPrefix/admin"
                  element={<AuthenticationGuard component={AdminView} />}
                >
                  <Route
                    path="/:organizationPrefix/admin/dashboard"
                    element={<AuthenticationGuard component={AdminDashboard} />}
                  />
                  {/* Admin Listing views */}
                  <Route
                    path="/:organizationPrefix/admin/alldogs"
                    element={<AuthenticationGuard component={AdminAllDogs} />}
                  />
                  <Route
                    path="/:organizationPrefix/admin/allpendingdogs"
                    element={<AuthenticationGuard component={AdminAllPendingDogs} />}
                  />
                  <Route
                    path="/:organizationPrefix/admin/allusers"
                    element={<AuthenticationGuard component={AdminAllUsers} />}
                  />
                  <Route
                    path="/:organizationPrefix/admin/alllitters"
                    element={<AuthenticationGuard component={AdminAllLitters} />}
                  />
                  <Route
                    path="/:organizationPrefix/admin/allpendinglitters"
                    element={
                      <AuthenticationGuard component={AdminAllPendingLitters} />
                    }
                  />
                  <Route
                    path="/:organizationPrefix/admin/allcompetitionresults"
                    element={
                      <AuthenticationGuard
                        component={AdminAllCompetitionResults}
                      />
                    }
                  />
                  <Route
                    path="/:organizationPrefix/admin/alltreedogs"
                    element={<AuthenticationGuard component={AdminAllTreedogs} />}
                  />
                  <Route
                    path="/:organizationPrefix/admin/allorganizations"
                    element={
                      <AuthenticationGuard component={AdminAllOrganizations} />
                    }
                  />
                  <Route
                    path="/:organizationPrefix/admin/allcertificates"
                    element={
                      <AuthenticationGuard component={AdminAllCertificates} />
                    }
                  />
                  <Route
                    path="/:organizationPrefix/admin/allresultproviders"
                    element={
                      <AuthenticationGuard component={AdminAllResultproviders} />
                    }
                  />
                  {/* Admin Single document views */}
                  <Route
                    path="/:organizationPrefix/admin/dog/:id"
                    element={<AuthenticationGuard component={AdminDogView} />}
                  />
                  <Route
                    path="/:organizationPrefix/admin/litter/:id"
                    element={<AuthenticationGuard component={AdminLitterView} />}
                  />
                  <Route
                    path="/:organizationPrefix/admin/user/:id"
                    element={<AuthenticationGuard component={AdminUserView} />}
                  />
                  <Route
                    path="/:organizationPrefix/admin/competitionresult/:id"
                    element={
                      <AuthenticationGuard
                        component={AdminCompetitionResultView}
                      />
                    }
                  />
                  <Route
                    path="/:organizationPrefix/admin/organization/:id"
                    element={
                      <AuthenticationGuard component={AdminOrganizationView} />
                    }
                  />
                  <Route
                    path="/:organizationPrefix/admin/organization"
                    element={
                      <AuthenticationGuard component={AdminOrganizationView} />
                    }
                  />
                  <Route
                    path="/:organizationPrefix/admin/certificate/:id"
                    element={
                      <AuthenticationGuard component={AdminCertificateView} />
                    }
                  />
                  <Route
                    path="/:organizationPrefix/admin/resultprovider/:id"
                    element={
                      <AuthenticationGuard component={AdminResultproviderView} />
                    }
                  />
                  <Route
                    path="/:organizationPrefix/admin/pedigreetool/:id?"
                    element={
                      <AuthenticationGuard component={AdminPedigreeTool} />
                    }
                  />
                <Route
                  path="/:organizationPrefix/admin/treedog-addition/:id?"
                  element={<AuthenticationGuard component={TreedogAdditionContainer} />}
                />
                <Route
                  path="/:organizationPrefix/admin/treedog-addition/:id/success"
                  element={<AuthenticationGuard component={TreedogAdditionSuccess} />}
                />
                  <Route
                    path="/:organizationPrefix/admin/treedog-addition"
                    element={
                      <AuthenticationGuard component={AdminPedigreeTool} />
                    }
                  />
          </Route>
          </Route>
            </Routes>
            <ScrollToTop />
          </Container>
          <Footer />
        </Suspense>
      </ErrorBoundary>
      <LoaderOverlay />
      <SnackbarManager />
    </>
  );
}
