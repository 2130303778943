import { useParams } from "react-router-dom";
import { atom, selector, selectorFamily } from "recoil";
import {
  getAllCertificates,
  getAllCompetitionResults,
  getAllDogs,
  getAllLitters,
  getAllOrganizations,
  getAllResultproviders,
  getAllPendingDiffs,
  getAllPendingDogs,
  getAllPendingLitters,
  getAllTreedogs,
  getAllUsers,
  getCertificateById,
  getCompetitionResultById,
  getDiffByParentId,
  getDogById,
  getLitterById,
  getOrganizationById,
  getResultproviderById,
  getUserById,
} from "api/adminRoutes";
import { accessTokenState } from "./globalStates";

// GET SELECTED DOCUMENT TYPE BY URL ID PARAM
export const selectedDocByIdState = selectorFamily({
  key: "selectedDocByIdState",
  get:
    (docType) =>
      async ({ get }) => {
        const { id: _id } = useParams(); // TODO: Pass this as props
        const accessToken = get(accessTokenState);

        switch (docType) {
          case "dog": {
            const response = getDogById(_id, { accessToken });
            if (response.error) {
              throw response;
            }
            return response;
          }
          case "litter": {
            const response = getLitterById(_id, { accessToken });
            if (response.error) {
              throw response;
            }
            return response;
          }
          case "certificate": {
            const response = getCertificateById(_id, { accessToken });
            if (response.error) {
              throw response;
            }
            return response;
          }
          case "organization": {
            const response = getOrganizationById(_id, { accessToken });
            if (response.error) {
              throw response;
            }
            return response;
          }
          case "resultprovider": {
            const response = getResultproviderById(_id, { accessToken });
            if (response.error) {
              throw response;
            }
            return response;
          }
          case "competitionResult": {
            const response = getCompetitionResultById(_id, { accessToken });
            if (response.error) {
              throw response;
            }
            return response;
          }
          case "user": {
            const response = getUserById(_id, { accessToken });
            if (response.error) {
              throw response;
            }
            return response;
          }

          default:
            break;
        }
      },
});

// SELECTED DOCUMENT TYPE BY URL ID PARAM
export const selectedDocState = atom({
  key: "selectedDocState",
  default: selectedDocByIdState,
});

// ATTACHMENTS MODIFICATION STATE
export const attachmentsModState = atom({
  key: "attachmentsModState",
  default: {
    images: {
      current: [],
      add: [],
      delete: [],
    },
    files: {
      current: [],
      add: [],
      delete: [],
    },
    links: {
      current: [],
      add: [],
      delete: [],
    },
    text: {
      current: [],
      add: [],
      delete: [],
    },
    primaryIndex: 0,
    primaryCategory: "current",
  },
});

// ORGANIZATIONS MODIFICATION STATE
export const organizationsModState = atom({
  key: "organizationsModState",
  default: [],
});

// ADMIN ALL PENDINGLITTERS
export const adminPendingLittersState = selector({
  key: "adminPendingLittersState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllPendingLitters({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN ALL LITTERS
export const adminAllLittersState = selector({
  key: "adminAllLittersState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllLitters({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN ALL PENDING DOGS
export const adminPendingDogsState = selector({
  key: "adminPendingDogsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllPendingDogs({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN ALL PENDING DOGS but no dogs that are in litters
export const adminPendingDogsNoLitterState = selector({
  key: "adminPendingDogsNoLitterState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllPendingDogs({ accessToken }, true);
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN ALL DOGS
export const adminAllDogsState = selector({
  key: "adminAllDogsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllDogs({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// Function to create a dynamic selector using selectorFamily
export const createAdminAllOrgDogsState = selectorFamily({
  key: 'adminAllDogsState',
  get: (organizationPrefix) => async ({ get }) => {
    try {
      const accessToken = get(accessTokenState);
      const query = {
        __t: "Registrable",
        pendingRegistration: false,
        "organizations.prefix": organizationPrefix,
      };
      const response = await getAllDogs({ accessToken, query });
      if (response.error) {
        throw response.error;
      }
      return response;
    } catch (error) {
      console.error("Error in createAdminAllOrgDogsState:", error);
      throw error;
    }
  },
});

// ADMIN ALL TREE DOGS
export const adminAllTreedogsState = selector({
  key: "adminAllTreeogsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllTreedogs({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN DOG BY ID
export const adminDogByIdState = selectorFamily({
  key: "adminDogByIdState",
  get:
    (id) =>
      ({ get }) => {
        const dogs = get(adminAllDogsState);
        const found = dogs.find((c) => c._id === id);
        if (!found) {
          return null;
        }
        return found;
      },
});

// Admin list of appropriate parent dogs
export const createAdminAllOrgDamsState = selectorFamily({
  key: 'adminAllOrgDamsState',
  get: (organizationPrefix) => async ({ get }) => {
    try {
      const accessToken = get(accessTokenState);

      // Do not include deceased dams.
      // (Allow deceased sires because of frozen semen.)
      const query = {
        __t: "Registrable",
        pendingRegistration: false,
        "organizations": {
          $elemMatch: {
            prefix: organizationPrefix,
            regClass: { $in: ["reg.classes.full", "reg.classes.limited", "reg.classes.unregparent"] }
          }
        },
        isDeceased: false,
        sex: "female",
      };

      const response = await getAllDogs({ accessToken, query });
      if (response.error) {
        throw response.error;
      }
      return response;
    } catch (error) {
      console.error("Error in createAdminAllOrgDamsState:", error);
      throw error;
    }
  },
});

// Admin list of appropriate sires
export const createAdminAllOrgSiresState = selectorFamily({
  key: 'adminAllOrgSiresState',
  get: (organizationPrefix) => async ({ get }) => {
    try {
      const accessToken = get(accessTokenState);
      const query = {
        __t: "Registrable",
        pendingRegistration: false,
        "organizations": {
          $elemMatch: {
            prefix: organizationPrefix,
            regClass: { $in: ["reg.classes.full", "reg.classes.unregparent"] }
          }
        },
        sex: "male",
      };
      const response = await getAllDogs({ accessToken, query });
      if (response.error) {
        throw response.error;
      }
      return response;
    } catch (error) {
      console.error("Error in createAdminAllOrgSiresState:", error);
      throw error;
    }
  },
});

// ADMIN ALL PENDING DIFFS
export const adminPendingDiffsState = selector({
  key: "adminPendingDiffsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllPendingDiffs({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN DIFF BY PARENTDOC ID
export const adminDiffByParentIdState = selectorFamily({
  key: "adminDiffByParentIdState",
  get: (parentDocType) => async ({ get }) => {
    const { id: _id } = useParams();
    const accessToken = get(accessTokenState);
    const diffs = await getDiffByParentId({ accessToken, _id, parentDocType });
    switch (diffs.length) {
      case 0:
        return null;
      case 1:
        return diffs[0];
      default:
        throw new Error("Multiple diffs found for parentDocId");
    }
  },
});

// Admin list of appropriate users
export const createAdminAllOrgUsersState = selectorFamily({
  key: 'adminAllOrgUsersState',
  get: (organizationPrefix) => async ({ get }) => {
    try {
      const accessToken = get(accessTokenState);
      const query = {
        "organizations.prefix": organizationPrefix,
      };
      const options = {
        sort: { name: 1 }
      };
      const response = await getAllUsers({ accessToken, query, options });
      if (response.error) {
        throw response.error;
      }
      return response;
    } catch (error) {
      console.error("Error in createAdminAllOrgUsersState:", error);
      throw error;
    }
  },
});

// Admin list of appropriate breeders
export const createAdminAllOrgBreedersState = selectorFamily({
  key: 'adminAllOrgBreedersState',
  get: (organizationPrefix) => async ({ get }) => {
    try {
      const accessToken = get(accessTokenState);
      const query = {
        "organizations.prefix": organizationPrefix,
        "organizations.role": "breeder",
        "organizations.displayBreeder": true,
      };
      const options = {
        sort: { name: 1 }
      };
      const response = await getAllUsers({ accessToken, query, options });
      if (response.error) {
        throw response.error;
      }
      return response;
    } catch (error) {
      console.error("Error in createAdminAllOrgBreedersState:", error);
      throw error;
    }
  },
});

// ADMIN ALL USERS
export const adminAllUsersState = selector({
  key: "adminAllUsersState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllUsers({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN ALL ORGANIZATIONS
export const adminAllOrgsState = selector({
  key: "adminAllOrgsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllOrganizations({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN ALL RESULT PROVIDERS
export const adminAllResultprovidersState = selector({
  key: "adminAllResultprovidersState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllResultproviders({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN ALL CERTIFICATES
export const adminAllCertsState = selector({
  key: "adminAllCertsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllCertificates({ accessToken });
    if (response.error) {
      throw response;
    }
    if (response === null || response === undefined) {
      return [];
    }
    return response;
  },
});

// ADMIN ALL COMPETITION RESULTS
export const adminAllCompetitionResultsState = selector({
  key: "adminAllCompetitionResultsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllCompetitionResults({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN GET BREEDER BY ID
export const adminGetBreederByIdState = selectorFamily({
  key: "adminGetBreederByIdState",
  get:
    (breeder) =>
      ({ get }) => {
        const users = get(adminAllUsersState);
        if (!breeder) {
          return null;
        }
        if (breeder) {
          const breederObject = users.find((u) => u._id === breeder);
          if (breederObject) {
            return breederObject;
          } else {
            return "hidden";
          }
        }
      },
});

// ADMIN GET OWNERS BY ID
export const adminGetOwnersByIdState = selectorFamily({
  key: "adminGetOwnersByIdState",
  get:
    (owners) =>
      ({ get }) => {
        const users = get(adminAllUsersState);
        if (!owners) {
          return [];
        }
        let array = [];
        owners.forEach((o) => {
          if (o !== null) {
            const ownerObject = users.find((u) => u._id === o);
            if (ownerObject) {
              array.push(ownerObject);
            } else {
              array.push("hidden");
            }
          }
        });
        return array;
      },
});
